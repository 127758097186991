import * as Api from "../network/api";
import { User } from '../models/user';
import '../styles/PlansPage.css';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFaceSadCry, faFaceTired, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Plan } from "../models/plan";
import Spinner from "./Spinner";
import colorPalette from "../utils/colors";
import ChartComponent from "./ChartComponent";


interface PlansPageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
    onPlanUpdated: (user:User) => void
}

const PlansPageLoggedInView = ({user, onLogoutSuccessful, onPlanUpdated}:  PlansPageLoggedInProps) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [plans, setPlans] = useState<Array<Plan>>([]);
    const [loading, setLoading] = useState(true)
    const [selectingPlan, setSelectingPlan] = useState<Plan | null>(null);
    const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
    const [viewingPlan, setViewingPlan] = useState<Plan | null>(null);


    useEffect(() => {
        getPlans();
    }, []);

    async function getPlans(){
        const response = await Api.getPlans().then(res=>{
            setPlans(res);
            console.log(res)
            setLoading(false)
        });
    }

    async function handleSetClicked(plan:Plan) {
        setSelectingPlan(plan)
        try {
            // Clone the user object to avoid direct mutation
            const newUser = { ...user };
            
            newUser.currentPlan = plan;
        
            // Update the user data via API
            await Api.updateUser(user._id, newUser);
            onPlanUpdated(newUser)
                
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
        setSelectingPlan(null)
    }

    async function logout() {
        try {
            await Api.logout();
            onLogoutSuccessful();
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    const addPlan = (newPlan: Plan) => {
        setPlans((prevPlans) => {
            // Find the index of the plan with the same title
            const planIndex = prevPlans.findIndex(plan => plan.planId === newPlan.planId);
    
            // If the plan exists, update it
            if (planIndex !== -1) {
                return [
                    ...prevPlans.slice(0, planIndex),
                    newPlan,
                    ...prevPlans.slice(planIndex + 1),
                ];
            }
    
            // If the plan doesn't exist, add it to the list
            return [...prevPlans, newPlan];
        });
    };

    async function handlePlanUpload() {
        try {
            if (selectedFile){
                const planFile = await selectedFile.arrayBuffer();
                const planFileText = new TextDecoder().decode(planFile);
        
                // Parse the JSON data
                let planData;
                try {
                    planData = JSON.parse(planFileText);
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                    alert("Error parsing JSON file");
                    return;
                }
    
                // Uplaod the plan data via API
                const response = await Api.createPlan(planData).then(res=>{
                    addPlan(res);
                })
            }

                
        } catch (error) {
            console.error('Error uploading plan:', error);
            alert('Failed to save changes');
        }
    }

    function handleFileOpen(e:React.ChangeEvent<HTMLInputElement>){
        if (e.target.files){
            const file = e.target.files[0]

            if (file.type === 'application/json'){
                setSelectedFile(file)
            }else{
                alert('Please Upload A JSON File')
                setSelectedFile(null)
            }
        }else{
            alert('Something Went Wrong')
        }
    }

    return (
        <div className="plans-page">
            {loading ? (
                <Spinner text="Loading Plans"/>
            ) : (
                viewingPlan ?
                (                
                    <div className='card-enlarged' style={{backgroundColor:'#ffffff66', height:'75vh', width:'100%', alignItems:'flex-start', flexDirection:'row',}}>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:`${colorPalette.default}88`, width:'100%', height:'8vh', borderRadius:'0'}}>
                            <div style={{backgroundColor:'transparent',  display:'flex', alignItems:'center', width:'100%'}}>
                                <FontAwesomeIcon className="back-button" style={{width:'2vw', height:'2vw', marginLeft:'0.75vw', padding:'5px'}} onClick={()=>setViewingPlan(null)} icon={faArrowLeft}></FontAwesomeIcon>
                            </div>
                        </div>

                        <div style={{display:'flex', width:'100%', height:'90%', flexDirection:'row', alignItems:'flex-start'}}>

                                <div style={{display:'flex', width:'100%', height:'100%', flex:1, backgroundColor:'#f1f1f1', flexDirection:'column'}}>
                                    <label style={{color:'#565656', marginTop: '10px', padding:'5px 20px', fontSize:'1.5vw', fontWeight: 'bold', fontStyle:'italic', justifyContent:'center'}}>{viewingPlan.title.toUpperCase()}</label>
                                    <label style={{color:'#565656', fontSize:'1.125vw', textAlign:'justify', padding:'0px 20px', maxLines:3}}>{`${viewingPlan.description.slice(0, 400)}...`}</label>

                                    <div style={{display: 'flex', marginTop:'1vh',margin:'10px 20px',alignItems: 'flex-start', flexDirection:'column', justifyContent:'flex-start'}}>
                                        <div style={{backgroundColor: `${colorPalette.steelBlue}dd`, padding: '6px 10px', width:'100%',borderRadius: '4px', marginTop: '5px', color: '#fff', fontWeight: 'bold'}}>
                                            {`${viewingPlan.content.length} Weeks`}
                                        </div>
                                        <div style={{backgroundColor: `${colorPalette.steelBlue}`, padding: '6px 10px', width:'100%',borderRadius: '4px', marginTop: '5px',color: '#fff', fontWeight: 'bold'}}>
                                            {`${viewingPlan.weeklyAdjustmentValue * 100}% Per Week`}
                                        </div>
                                        <div style={{backgroundColor: `${colorPalette.steelBlue}dd`, padding: '6px 10px',width:'100%', borderRadius: '4px', marginTop:'5px',color: '#fff', fontWeight: 'bold'}}>
                                            {viewingPlan.baseLoad <= 200 ? `Beginner`:viewingPlan.baseLoad > 200 && viewingPlan.baseLoad <= 400 ? `Intermediate`: `Advanced`}
                                        </div>
                                        <div style={{backgroundColor: `${colorPalette.steelBlue}`, padding: '6px 10px', width:'100%',borderRadius: '4px', marginTop:'5px',color: '#fff', fontWeight: 'bold'}}>
                                            {viewingPlan.weeklyAdjustmentValue < 0.025 ? `Steady Improvement`:viewingPlan.weeklyAdjustmentValue > 0.025 && viewingPlan.weeklyAdjustmentValue <= 0.05 ? `Standard Improvement`: `Fast Improvement`}
                                        </div>
                                    </div>
                                    <button className="set-plan-button" 
                                                style={{
                                                    fontWeight: 'bold', 
                                                    color: '#fff', 
                                                    padding: '10px 15px', 
                                                    borderRadius: '5px', 
                                                    border: 'none', 
                                                    cursor: 'pointer', 
                                                    width: 'auto',
                                                    transition: 'background-color 0.3s',
                                                    margin:'0px 20px',
                                                }}
                                                disabled={selectingPlan !== null}
                                                onClick={() => handleSetClicked(viewingPlan)}
                                        >
                                            {selectingPlan === viewingPlan ? `Setting...` : `Set As My Plan`}
                                        </button>
                                </div>
                                <div style={{display:'flex', width:'100%', height:'100%', flex:2, flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                    <label style={{color:'#565656', marginTop: '10px', marginLeft:'1vw', fontSize:'1.5vw', fontWeight:'bold'}}>Week 1 Training Load Overview</label>
                                    <ChartComponent mh={80} mw={100} weekData={viewingPlan.content[0].days} user={user}></ChartComponent>
                                </div>
                            </div>
                    </div>
                )
                :
                (                
                    <div style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
                        <h2 className="title" style={{fontStyle:'italic', color:colorPalette.steelBlue, fontSize:'3vw', fontWeight:'normal'}}><b>VITOVA</b> PLAN LIBRARY</h2>

                        <label style={{ fontSize: '1.5vw', color:'#454545', marginBottom:'10px', fontStyle:'italic', fontWeight:'normal'}} className='main-title'>OUR COLLECTION OF PLANS <b>ADAPTABLE</b> TO YOUR CONDITIONS</label>
                        <div style={{ overflowY: 'auto', maxHeight: '65vh', padding:'10px 30px'}}>
                            <ul className="plan-container" style={{ listStyleType: 'none', padding: 0 }}>
                                {plans.map((plan, index) => (
                                    <li key={index} className="plan-item" style={{}}>
                                        <div className="plan-card" onClick={() => setViewingPlan(plan)} 
                                            style={{
                                                width: '100%', 
                                                position: 'relative', 
                                                backgroundColor: `white`, 
                                                padding: '15px', 
                                                borderRadius: '8px', 
                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                cursor: 'pointer',
                                                transition: 'transform 0.2s'
                                            }}
                                            // onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.02)'}
                                            // onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                                            >
 
                                            <h2 style={{marginTop: '10px', marginBottom: '5px', fontSize: '20px', color: '#454545', fontWeight: 'bold', fontStyle:'italic'}}>
                                                {plan.title.toUpperCase()}
                                            </h2>
                                            
                                            <p style={{color: '#898989', fontSize: '14px', marginBottom: '10px'}}>
                                                {plan.description.length > 60 ? `${plan.description.slice(0, 60)}...` : plan.description}
                                            </p>
                                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <div style={{backgroundColor: `${colorPalette.steelBlue}`, padding: '6px 10px', borderRadius: '4px', marginRight: '10px', color: '#fff', fontWeight: 'bold'}}>
                                                        {`${plan.content.length} Weeks`}
                                                    </div>
                                                    <div style={{backgroundColor: `${colorPalette.steelBlue}dd`, padding: '6px 10px', borderRadius: '4px', marginRight: '10px',color: '#fff', fontWeight: 'bold'}}>
                                                        {`${plan.weeklyAdjustmentValue * 100}% Per Week`}
                                                    </div>
                                                    <div style={{backgroundColor: `${colorPalette.steelBlue}`, padding: '6px 10px', borderRadius: '4px', marginRight:'10px',color: '#fff', fontWeight: 'bold'}}>
                                                        {plan.baseLoad <= 200 ? `Beginner`:plan.baseLoad > 200 && plan.baseLoad <= 400 ? `Intermediate`: `Advanced`}
                                                    </div>
                                                    <div style={{backgroundColor: `${colorPalette.steelBlue}dd`, padding: '6px 10px', borderRadius: '4px', color: '#fff', fontWeight: 'bold'}}>
                                                        {plan.weeklyAdjustmentValue < 0.025 ? `Steady Improvement`:plan.weeklyAdjustmentValue > 0.025 && plan.weeklyAdjustmentValue <= 0.05 ? `Standard Improvement`: `Fast Improvement`}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <button className="set-plan-button" 
                                                    style={{
                                                        fontWeight: 'bold', 
                                                        backgroundColor: colorPalette.dodgerBlue, 
                                                        color: '#fff', 
                                                        padding: '10px 15px', 
                                                        borderRadius: '5px', 
                                                        border: 'none', 
                                                        cursor: 'pointer', 
                                                        width: '100%',
                                                        transition: 'background-color 0.3s'
                                                    }}
                                                    disabled={selectingPlan !== null}
                                                    onClick={() => handleSetClicked(plan)}
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = `${colorPalette.lightSeaGreen}dd`}
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = colorPalette.dodgerBlue}
                                            >
                                                {selectingPlan === plan ? `Setting...` : `Set As My Plan`}
                                            </button> */}
                                        </div>
                                    </li>

                                ))}
                                {/* {user.fullname === 'Nicholas Latham' && 
                                    <li className="plan-item" >
                                        <div className='plan-card' style={{ width: '100%', position: 'relative', backgroundColor: `${colorPalette.steelBlue}77`, padding: '10px' }}>
                                            <input type="file" style={{ marginBottom: '15px' }} onChange={e => handleFileOpen(e)} />
                                        </div>
                                        <button className="set-plan-button" onClick={handlePlanUpload}>Upload Plan</button>
                                    </li>
                                } */}

                            </ul>
                        </div>
                    </div>
                )
                


            )}
        </div>
    );
};

export default PlansPageLoggedInView;