import * as Api from "../network/api";
import {User } from '../models/user';
import profileImage from '../svg-icons/profile.svg';
import '../styles/GPDashboardPage.css';

import { useEffect, useState } from "react";
import Spinner from "./Spinner";
import colorPalette from "../utils/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBowlFood, faExclamationCircle, faMessage, faSmoking, faUserDoctor } from "@fortawesome/free-solid-svg-icons";

interface GPDashboardPageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
}

const GPDashboardPageLoggedInView = ({ user, onLogoutSuccessful }: GPDashboardPageLoggedInProps) => {
    const [patients, setPatients] = useState<Array<User>>([]);
    const [filteredPatients, setFilteredPatients] = useState<Array<User>>([]);

    const [loading, setLoading] = useState(true);
    const [expandedCard, setExpandedCard] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('')

    const toggleCard = (id: string) => {
        setExpandedCard(expandedCard === id ? null : id);
    };

    useEffect(() => {
        getMyPatients();
    }, []);

    async function getMyPatients(){
        const body:Api.GetPatientsBody = {gpReferralCode:user.gpReferralCode}
        const response = await Api.getPatients(body).then(res=>{
            setPatients(res.filter((p:User)=>p._id!==user._id));
            
        });
        setLoading(false);
        setFilteredPatients(patients)
        handleSearch('');

    }

    const handleSearch = (value: string = searchTerm) => {
        setSearchTerm(value);
        if (value.trim().length === 0){
            setFilteredPatients(patients);
        }else{
            setFilteredPatients(patients.filter(p=>p.fullname.toLowerCase().includes(value.toLowerCase())));
        }

    }

    function decimalToHexColor(value:number) {
        // Ensure the input is within the range [0, 1]
        value = Math.max(0, Math.min(1, value));
    
        // Calculate the red and green components
        const red = Math.round(255 * (1 - value)); // Red decreases as the value increases
        const green = Math.round(255 * value);    // Green increases as the value increases
    
        // Convert to hexadecimal format
        const redHex = red.toString(16).padStart(2, '0');
        const greenHex = green.toString(16).padStart(2, '0');
    
        // Blue is always 0 for this gradient
        const blueHex = '00';
    
        // Combine into a hex color string
        return `#${redHex}${greenHex}${blueHex}`;
    }

    return (
        loading ? (
            <div>
                <Spinner text="Loading Patients..." />
            </div>
        ) : (
            patients.length > 0 ? (
                <div 
                    className="dashboard-page" 
                    style={{ backgroundColor: '#f1f1f1', justifyContent: 'flex-start' }}
                >
                    <label 
                        style={{ 
                            fontStyle: 'italic', 
                            fontWeight: 'bold', 
                            fontSize: '2vw', 
                            marginBottom: '20px', 
                            display: 'block' 
                        }}
                    >
                        PATIENTS DASHBOARD
                    </label>
                    <input
                            type="text"
                            placeholder="Search patients..."
                            value={searchTerm}
                            className="search-bar"
                            autoFocus
                            onChange={(e) => handleSearch(e.target.value)}
                        />

                    <div className="patient-grid">
                        {(searchTerm.trim().length > 0 ? filteredPatients:patients).map((patient: User) => (
                            <div className="patient-card" key={patient._id}>
                                {/* Patient details */}
                                <div
                                    className="patient-details"
                                    onClick={() => toggleCard(patient._id)}
                                >
                                    <div className="patient-column steel-blue">
                                        <label style={{fontWeight:'bold', fontStyle:'italic'}} className="patient-name-label">
                                            {patient.fullname.toUpperCase()}
                                        </label>
                                    </div>
                                    <div className="patient-column slate-gray">
                                        <label className="patient-name-label">
                                            {patient.currentPlan.title} Plan
                                        </label>
                                    </div>
                                    <div className="patient-column slate-gray">
                                        <label className="patient-name-label">
                                            {patient.info.conditions[0].condition.name}
                                        </label>
                                    </div>
                                    <div className="patient-column slate-gray">
                                        <label className="patient-name-label">
                                            {patient.info.height}CM
                                        </label>
                                    </div>
                                    <div className="patient-column slate-gray">
                                        <label className="patient-name-label">
                                            {patient.info.weight}KG
                                        </label>
                                    </div>
                                    <div className="patient-column slate-gray">
                                        <label className="patient-name-label">
                                            {patient.info.waistCircumference}IN
                                        </label>
                                    </div>
                                    <div style={{width:'100%', height:'100%', justifyContent:'center', alignItems:'center', display:'flex'}} className="patient-column slate-gray">
                                        <FontAwesomeIcon style={{height:'20px',color:decimalToHexColor(0.2)}} icon={faSmoking}/>
                                    </div>
                                    <div style={{width:'100%', height:'100%', justifyContent:'center', alignItems:'center', display:'flex'}} className="patient-column slate-gray">
                                        <FontAwesomeIcon style={{height:'20px',color:decimalToHexColor(0.9)}} icon={faBowlFood}/>
                                    </div>
                                    <div style={{width:'100%', height:'100%', justifyContent:'center', alignItems:'center', display:'flex'}} className="patient-column slate-gray">
                                        <FontAwesomeIcon style={{height:'20px',color:decimalToHexColor(0.6)}} icon={faUserDoctor}/>
                                    </div>
                                </div>

                                {/* Expanded section */}
                                <div
                                    className={`expanded-section ${
                                        expandedCard === patient._id ? 'expanded' : ''
                                    }`}
                                >
                                    <div className="expanded-content" style={{display:'flex', alignContent:'flex-start', flexDirection:'column', justifyContent:'flex-start', width:'100%'}}>
                                        <p style={{margin:0, fontSize:'1.5vw'}} className="patient-name-label"><strong>{patient.fullname.toUpperCase()}</strong></p>
                                        <p style={{margin:0}}className="patient-name-label">Plan: {patient.currentPlan.title}</p>
                                        <p style={{margin:0}}className="patient-name-label">Contact: {patient.email}</p>
                                        <p style={{margin:0}}className="patient-name-label">Height: {patient.info.height}CM</p>
                                        <p style={{margin:0}}className="patient-name-label">Weight: {patient.info.weight}KG</p>
                                        <p style={{margin:0}}className="patient-name-label">Waist Circumference: {patient.info.waistCircumference}IN</p>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div 
                    className="dashboard-page" 
                    style={{ backgroundColor: '#f1f1f1' }}
                >
                    <label 
                        style={{ 
                            fontStyle: 'italic', 
                            fontWeight: 'bold', 
                            fontSize: '3vw' 
                        }}
                    >
                        Your Patients Will Appear Here
                    </label>
                    <label 
                        style={{ marginTop: '5px' }}
                    >
                        {`Your GP referral code is ${user.gpReferralCode}`}
                    </label>
                </div>
            )
        )
    );
}

export default GPDashboardPageLoggedInView;