import { Alert, Button, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { ConflictError, UnauthorizedError } from "../errors/http_errors";
import * as Api from "../network/api";
import { SignUpCredentials } from "../network/api";
import { useForm } from "react-hook-form";
import { User } from "../models/user";
import '../styles/HomePage.css';
import { jwtDecode, JwtPayload } from "jwt-decode";
import colorPalette from "../utils/colors";

// Extend JwtPayload to include Google-specific properties
interface GoogleJwtPayload extends JwtPayload {
    email: string;
    name: string;
    picture: string;
}

interface SignUpModalProps {
    onSignUpSuccessful: (user: User) => void,
    onLoginSuccessful: (user: User) => void,
}

declare const window: any;

const HomePageLoggedOutView = ({ onSignUpSuccessful, onLoginSuccessful }: SignUpModalProps) => {
    const [errorText, setErrorText] = useState<string | null>(null);
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<SignUpCredentials>();

    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(true);

    const [isFadingOut, setIsFadingOut] = useState(false);
    const [isFadingIn, setIsFadingIn] = useState(false);

    const [GPAccountChecked, setGPAccountChecked] = useState(false);

    // Google Sign-In Initialization
    // useEffect(() => {
    //     const loadGoogleScript = () => {
    //         const script = document.createElement("script");
    //         script.src = "https://accounts.google.com/gsi/client";
    //         script.async = true;
    //         script.defer = true;
    //         document.body.appendChild(script);

    //         script.onload = initializeGoogleSignIn;
    //     };

    //     const initializeGoogleSignIn = () => {
    //         window.google.accounts.id.initialize({
    //             client_id: "",
    //             callback: handleGoogleResponse
    //         });

    //         window.google.accounts.id.renderButton(
    //             document.getElementById("googleSignInButton"),
    //             { theme: "outline", size: "large" }
    //         );
    //     };

    //     loadGoogleScript();
    // }, []);

    // Handle Google Sign-In response
    const handleGoogleResponse = async (response: any) => {
        try {
            if (!response.credential) {
                throw new Error("No credential found in the response.");
            }

            const userObject = jwtDecode<GoogleJwtPayload>(response.credential);

            const googleId = userObject.sub;
            const email = userObject.email;
            const fullName = userObject.name;
            const profilePicture = userObject.picture;

            if (googleId) {
                try{
                    const signupCredentials: Api.SignUpCredentials = {
                        accountType: 'ffs',
                        email: email,
                        fullname: fullName,
                        password: googleId,
                        GPAccount: false,
                    };

                    const user = await Api.signUp(signupCredentials);
                    onSignUpSuccessful(user);
                } catch {
                    console.log("Google sign up failed")
                }

                try{
                    const loginCredentials: Api.LoginCredentials = {
                        username: email,
                        password: googleId,
                    };

                    const user = await Api.login(loginCredentials);
                    onLoginSuccessful(user);                
                }catch (e:any){
                    console.log("Google login failed")
                    console.log(e)
                }

            } else {
                throw new Error("Google ID is missing.");
            }
        

        } catch (error) {
            console.error("Error handling Google Sign-In response:", error);
            setErrorText("Google Sign-In failed. Please try again.");
        }
    };

    async function onSubmit(credentials: any) {
        if (showLoginModal) {
            try {
                const user = await Api.login(credentials);
                onLoginSuccessful(user);
            } catch (error) {
                if (error instanceof UnauthorizedError) {
                    setErrorText(error.message);
                } else {
                    alert(error);
                }
                console.error(error);
            }
        } else {
            try {
                credentials.accountType = 'unset';
                credentials.GPAccount = GPAccountChecked;
                const newUser = await Api.signUp(credentials);
                onSignUpSuccessful(newUser);
            } catch (error) {
                if (error instanceof ConflictError) {
                    setErrorText(error.message);
                } else {
                    alert(error);
                }
                console.error(error);
            }
        }
    }

    const handleSignUpClick = () => {
        setIsFadingOut(true);
        setIsFadingIn(false);
        setTimeout(() => {
            setShowSignUpModal(true);
        }, 500); 
    };

    const handleLoginClick = () => {
        setIsFadingOut(true);
        setIsFadingIn(false);
        setTimeout(() => {
            setShowLoginModal(true);
        }, 500); 
    };

    const handleCancelClick = () => {
        setShowSignUpModal(false);
        setShowLoginModal(false);
        setIsFadingIn(true);
        setIsFadingOut(false);
        setTimeout(() => {
            setIsFadingIn(false);
        }, 500);
    };

    return (
        <div className="home-page" style={{ display: 'flex', height: '80vh', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            {!showLoginModal && !showSignUpModal && 
            <div className={`main-content ${isFadingOut ? 'fade-out' : ''} ${isFadingIn ? 'fade-in' : ''} ${showSignUpModal ? 'hidden' : ''}`}>
                <h2 style={{ fontSize: '3.5em', color: 'white' }} className="main-title">Unleash Your Potential</h2>
                <p style={{ color: 'white' }}>Train smart with our <b>Adaptive Training</b> technology</p>
                <button className="signup-button" style={{ borderRadius: '0px' }} onClick={handleSignUpClick}>Sign Up Free</button>
                <button className="signup-button" style={{ borderRadius: '0px' }} onClick={handleLoginClick}>Login</button>
            </div>
            }

            {showSignUpModal && (
                <div className="modal-overlay">
                    <div className="modal-content fade-in" style={{ borderRadius: '5px', backgroundColor: '#ffffff' }}>
                        <Form onSubmit={handleSubmit(onSubmit)} className="signup-form">
                            <h3 style={{ fontSize: '2.5vw', fontWeight: 'bold', color: 'steelblue', fontStyle: 'italic' }}>JOIN US</h3>
                            {errorText && <Alert variant="danger">{errorText}</Alert>}
                            <Form.Group controlId="formBasicFirstname">
                                <Form.Label style={{ color: '#454545' }}>Forename</Form.Label>
                                <Form.Control 
                                    type="text"
                                    placeholder="Forename" 
                                    {...register("fullname", { required: "Fullname is required" })} 
                                    className="user-form-input"
                                />
                                {errors.fullname && <Form.Text className="text-danger">{errors.fullname.message}</Form.Text>}
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label style={{ color: '#454545' }}>Email Address</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    placeholder="Email" 
                                    {...register("email", { required: "Email is required" })} 
                                    className="user-form-input"
                                />
                                {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
                            </Form.Group>
                            
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label style={{ color: '#454545' }}>Password</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder="Password" 
                                    {...register("password", { required: "Password is required" })} 
                                    className="user-form-input"
                                />
                                {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}
                            </Form.Group>
                            <Form.Group controlId="formBasicConfirmPassword">
                                <Form.Label style={{ color: '#454545' }}>Confirm Password</Form.Label>
                                <Form.Control 
                                    checked={GPAccountChecked}
                                    type="password" 
                                    placeholder="Confirm Password" 
                                    className="user-form-input"

                                />
                            </Form.Group>
                            <Form.Group style={{flexDirection:'row', display:'flex', marginTop:'10px', width:'100%', justifyContent:'center', alignItems:'center'}}>
                            <Form.Group controlId="formBasicAreYouGP" className="custom-checkbox-group">
                                    <Form.Check 
                                        style={{marginRight:'15px',marginBottom:'10px',}}
                                        type="checkbox" 
                                        checked={GPAccountChecked}
                                        onChange={(e)=>setGPAccountChecked(!GPAccountChecked)}
                                        className="custom-checkbox" 
                                    />
                                </Form.Group>
                                <Form.Label style={{ color: colorPalette.steelBlue, fontStyle:'italic', fontWeight:'bold'}}>I'M A GP</Form.Label>
                            </Form.Group>

                            <div className="button-group">
                                <Button variant="primary" type="submit" style={{ borderRadius: '0px', width: '100%' }} disabled={isSubmitting} className="submit-button">Sign Up</Button>
                            </div>

                            <label className="label-link" onClick={() => { setShowSignUpModal(false); setShowLoginModal(true); }} style={{ color: '#454545' }}>Already Have An Account?</label>
                        </Form>
                    </div>
                </div>
            )}

            {showLoginModal && (
                <div className="modal-overlay">
                    <div className="modal-content fade-in" style={{ borderRadius: '5px', backgroundColor: '#ffffff' }}>
                        <Form onSubmit={handleSubmit(onSubmit)} className="signup-form" style={{ borderRadius: '0px' }}>
                            <h3 style={{ fontSize: '2.5vw', fontWeight: 'bold', color: 'steelblue', fontStyle: 'italic' }}>LOGIN</h3>
                            {errorText && <Alert variant="danger">{errorText}</Alert>}
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label style={{ color: '#454545' }}>Email Address</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    placeholder="Email" 
                                    {...register("email", { required: "Email is required" })} 
                                    className="user-form-input"
                                />
                                {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
                            </Form.Group>
                            
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label style={{ color: '#454545' }}>Password</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder="Password" 
                                    {...register("password", { required: "Password is required" })} 
                                    className="user-form-input"
                                />
                                {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}
                            </Form.Group>
                            <div className="button-group">
                                <Button variant="primary" type="submit" disabled={isSubmitting} style={{ borderRadius: '0px', width: '100%' }} className="submit-button">Login</Button>
                            </div>

                            {/* Google Sign-In Button */}
                            {/* <div id="googleSignInButton" style={{ marginTop: '10px', textAlign: 'center', width:'100%'}}></div> */}

                            <label className="label-link" style={{ color: '#454545' }}>Forgot your password?</label>
                            <label className="label-link" onClick={() => { setShowLoginModal(false); setShowSignUpModal(true); setErrorText('')}} style={{ color: '#454545' }}>Don't have an account?</label>
                        </Form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default HomePageLoggedOutView;
