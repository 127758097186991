import React from 'react';
import '../styles/Spinner.css'

interface SpinnerProps {
    text: string;
}

const Spinner: React.FC<SpinnerProps> = ({ text }) => {
    return (
        <div className="spinner-container">
            <div className="spinner"></div>
            <p style={{color:'#454545', fontWeight:'bold', fontStyle:'italic'}} className="loading-label">{text.toUpperCase()}</p>
        </div>
    );
};

export default Spinner;
