function extractActivityStep(stringInput:string, stepName:string) {
    const activityStep = {
        stepName: stepName,
        stepType: "",
        stepDuration: -1,
        stepDistance: -1,
        stepZone: -1,
        stepIntensity: -1,
        stepReps: -1,
        stepSets: -1,
        stepExercise: "",
        stepDescription: stringInput.trim()
    };

    // Normalize input
    const normalizedInput = stringInput.toLowerCase();

    // Extract distance
    const distanceMatch = normalizedInput.match(/(\d+(?:\.\d+)?)\s*(km|m)/i);
    if (distanceMatch) {
        const distanceValue = parseFloat(distanceMatch[1]);
        activityStep.stepDistance = distanceMatch[2].toLowerCase() === "m" ? distanceValue / 1000 : distanceValue;
    }

    // Extract intensity
    const intensityMatch = normalizedInput.match(/intensity\s*(\d+)/i);
    if (intensityMatch) {
        activityStep.stepIntensity = parseInt(intensityMatch[1], 10);
    }

    // Extract activity type
    const activityTypes = { running: "Run", cycling: "Cycle", swimming: "Swim", walking: "Walk" };
    const activityMatch = normalizedInput.match(/\b(running|cycling|swimming|walking)\b/i);
    if (activityMatch) {
        activityStep.stepType = activityTypes[activityMatch[1].toLowerCase() as keyof typeof activityTypes] || activityMatch[1];
    }

    // Extract duration
    const durationMatch = normalizedInput.match(/(\d+)\s*(minutes|minute|seconds|second)/i);
    if (durationMatch) {
        const durationValue = parseInt(durationMatch[1], 10);
        activityStep.stepDuration = /minute/i.test(durationMatch[2]) ? durationValue * 60 : durationValue;
    }

    // Extract reps
    const repsMatch = normalizedInput.match(/(\d+)\s*reps?/i);
    if (repsMatch) {
        activityStep.stepReps = parseInt(repsMatch[1], 10);
    }

    // Extract sets
    const setsMatch = normalizedInput.match(/(\d+)\s*sets?/i);
    if (setsMatch) {
        activityStep.stepSets = parseInt(setsMatch[1], 10);
    }

    // Extract zone
    const zoneMatch = normalizedInput.match(/zone\s*(\d+)/i);
    if (zoneMatch) {
        activityStep.stepZone = parseInt(zoneMatch[1], 10);
    }

    // Extract exercise
    const exerciseMatch = normalizedInput.match(/\b(?:sets? of)?\s*\d+\s*reps?\s*(?:of\s*)?(.+)/i);
    if (exerciseMatch) {
        activityStep.stepExercise = exerciseMatch[1].trim();
    }

    const durationExerciseMatch = normalizedInput.match(/(\d+)\s*(minutes|minute|seconds|second)\s*of\s*(.+)/i);
    if (durationExerciseMatch) {
        const durationValue = parseInt(durationExerciseMatch[1], 10);
        const durationUnit = durationExerciseMatch[2].toLowerCase();
        activityStep.stepDuration = /minute/i.test(durationUnit) ? durationValue * 60 : durationValue; // Convert to seconds
        activityStep.stepExercise = durationExerciseMatch[3].trim(); // Extract the exercise name
    }
    

    return activityStep;
}

export default extractActivityStep;
