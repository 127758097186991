import * as Api from "../network/api";
import { Review, User } from '../models/user';
import '../styles/DashboardPage.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {fa1, fa2, fa3, faAdjust, faArrowLeft,faArrowRight,faArrowsTurnRight,faBan,faBarsProgress,faBed,faBeer,faBeerMugEmpty,faBicycle,faBowlFood,faC,faCalendar, faCalendarCheck, faCalendarDay, faCalendarDays, faCalendarWeek, faCaretSquareRight, faChartLine, faCheck, faChevronLeft, faChevronRight, faCross, faDownload, faDumbbell, faExclamationCircle, faExpandArrowsAlt, faFlag, faGripLinesVertical, faHeart, faHeartbeat, faHeartPulse, faHiking, faListCheck, faLongArrowAltRight, faM, faNeuter, faPerson, faPersonRunning, faRemove, faRightToBracket, faRobot, faRuler, faRunning, faSign, faSmile, faSmoking, faSwimmer, faTired, faUserDoctor, faW, faWeight } from "@fortawesome/free-solid-svg-icons";
import React, { act, useEffect, useState } from "react";
import { Activity, ActivityStep, Day, Exercise, Plan } from "../models/plan";
import { secondsToTimeString, timeStringToSeconds } from "../utility";
import Slider from '@mui/material/Slider';
import '../styles/SessionReview.css';
import { SliderProps } from '@mui/material/Slider'; // Ensure SliderProps is imported if necessary
import { Link } from "react-router-dom";

import colorPalette from '../utils/colors';
import { Button } from "react-bootstrap";
import ChartComponent from "./ChartComponent";
import { getTotalActivityLength } from "../utils/ActivityStats";
import { getSumTSS, getTSS } from "../utils/TSS";
import extractActivityStep from "../utils/ActivityStepExtractor";
import workoutBuilderGeneticAlgorithm, { GeneticAlgorithmOptions, updateWorkout } from "../utils/WorkoutGenerator";
import Spinner from "./Spinner";

interface DashboardPageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
}

type Zone = {zone: string, pace:Array<string>, hr:Array<number>};

type ChangesObject = {
    avgEffort:number,
    adjustedTSSValue:number,
    newTSS:number,
}

const options:GeneticAlgorithmOptions = {
    populationSize: 50,
    generations: 100,
    mutationRate: 0.05,
    crossoverRate:0.8
};

type ActivityType = 'Run' | 'Cycle' | 'Swim' | 'Strength';

const workoutOptions = {
    "Run": [0.15, 0.7, 0.15],
    "Cycle": [0.2, 0.8],
    "Swim":[0.15, 0.7, 0.15],
    "Strength":[0.2, 0.2, 0.2, 0.2, 0.2]
};


const DashboardPageLoggedInView = ({ user, onLogoutSuccessful }: DashboardPageLoggedInProps) => {
    const [selectedDay, setSelectedDay] = useState<Day |null>(null);
    const [userPlan, setUserPlan] = useState<Plan>(user.currentPlan);
    const [reviewingActivity, setReviewingActivity] = useState<Activity | null>(null);

    const [reviewStage, setReviewStage] = useState(0);
    const [duration, setDuration] = useState(-1);
    const [distance, setDistance] = useState(-1);

    const [pace, setPace] = useState(-1);
    const [power, setPower] = useState(-1);

    const [heartRate, setHeartRate] = useState(100);
    const [effort, setEffort] = useState(5);
    const [sleepQuality, setSleepQuality] = useState(5);

    const [selectedWorkoutPart, setSelectedWorkoutPart] = useState(0);
    const [selectedActivityIndex, setSelectedActivityIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    const [reviewType, setReviewType] = useState('manual');
    const [aiChanges, setAiChanges] = useState<ChangesObject>({avgEffort:5, newTSS:0,adjustedTSSValue:0});

    const [next7Days, setNext7Days] = useState<Day[]>([]);

    const [showCalendar, setShowCalendar] = useState(false);
    const [showRightPanel, setShowRightPanel] = useState(false);
    const [selectedTab, setSelectedTab] = useState('');

    const [viewportSize, setViewportSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    const toggleRightPanel = (tab?:string) => {
        if (tab){
            setSelectedTab(tab)
        }
        setShowRightPanel(!showRightPanel);
    };


    useEffect(() => {
        const handleResize = () => {
            setViewportSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        // Function to handle the adaptation of the next 7 days
        const asyncAdaptNext7Days = async () => {
            setLoading(true);  // Set loading to true before the computation starts
            await new Promise<void>((resolve) => {
                setTimeout(() => {
                    adaptNext7Days();
                    resolve(); // Resolve the promise with void
                }, 0);  // Ensure the adaptNext7Days runs asynchronously
            });
            setLoading(false); // Set loading to false after the computation is done
        };

        // Add the resize event listener
        window.addEventListener('resize', handleResize);

        // Call the async function to adapt the next 7 days
        asyncAdaptNext7Days();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePaceChange: SliderProps['onChange'] = (event:any, value:any) => {
        setPace(value as number);
    };

    const handlePowerChange: SliderProps['onChange'] = (event:any, value:any) => {
        setPower(value as number);
    };

    const handleDistanceChange: SliderProps['onChange'] = (event:any, value:any) => {
        setDistance(value as number);
    };

    const nextStage = () => setReviewStage(prevStage => prevStage + 1);
    const prevStage = () => setReviewStage(prevStage => Math.max(prevStage - 1, 0));

    function getNext7Days():Day[] {
        // Parse the start date string into a Date object
        const startDate = new Date(user.userPlanStartDate);
        
        // Get the current date and time
        const currentDate = new Date();
        
        // Ensure both dates are valid Date objects
        if (isNaN(startDate.getTime()) || isNaN(currentDate.getTime())) {
            throw new Error("Invalid Date");
        }
    
        // Calculate the difference in milliseconds
        const diffInMilliseconds = currentDate.getTime() - startDate.getTime();
        
        // Convert milliseconds to days
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const diffInDays = Math.floor(diffInMilliseconds / millisecondsPerDay);
        // const diffInDays = 2
        // Calculate the current week and day within that week
        let weekIndex = Math.floor(diffInDays / 7);
        if (weekIndex >= 0){
            return user.currentPlan.content[weekIndex].days;

        }else{
            return user.currentPlan.content[0].days;
        }
        let dayIndex = diffInDays % 7;
    
        // Ensure weekIndex and dayIndex are within valid range
        weekIndex = Math.max(0, weekIndex);
        dayIndex = Math.max(0, dayIndex);
    
        const next7Days = [];
        let remainingDays = 7;
    
        // Traverse the weeks and collect days
        while (remainingDays > 0) {
            if (weekIndex >= userPlan.content.length) {
                break; // Stop if we run out of weeks
            }
    
            const currentWeek = userPlan.content[weekIndex];
            const daysToAdd = Math.min(remainingDays, currentWeek.days.length - dayIndex);
    
            next7Days.push(...currentWeek.days.slice(dayIndex, dayIndex + daysToAdd));
            
            remainingDays -= daysToAdd;
            weekIndex += 1;
            dayIndex = 0; // Start from the beginning of the next week
        }
    
        return next7Days;
    }

    function adaptNext7Days() {
        var effortDistribution = 
        [7, 26,42, 30, 18, 7, 10]

        for (var i = 0; i < effortDistribution.length; i++){
            effortDistribution[i] = effortDistribution[i]/42 * 10;
        }
        
        // Get the next 7 days of activities
        var days = getNext7Days();
        if (!user.currentPlan.title.includes('VitovaAI')){
            setNext7Days(days);

            return;
        }
        // setNext7Days(days);
        // return;
        // Get the last 7 reviews from the user
        const last7Reviews = user.reviews.slice(-7).filter(r=>r.results.effort>=0); // Simplified slice
        
        // Calculate the average effort from the last 7 reviews
        var sumEffortDifferences = 0;
        for (const review of last7Reviews) {
            sumEffortDifferences += effortDistribution[review.day] - review.results.effort;
        }
        const averageEffort = last7Reviews.length ? sumEffortDifferences / last7Reviews.length : 0;
    
        // Get Expected Effort Value

        // Calculate the load adjustment value
        const loadAdjustmentValue = averageEffort / 20;
    
        // Calculate the current and new Weekly TSS (Training Stress Score)
        const currentWeekNumber = getWeekNumber(days[0]);
        const currentWeeklyTSS = user.currentPlan.baseLoad + (currentWeekNumber * user.currentPlan.weeklyAdjustmentValue);
        const newWeeklyTSS = last7Reviews.length > 0 ? currentWeeklyTSS + (currentWeeklyTSS * loadAdjustmentValue) : currentWeeklyTSS;
    
        // Adjust the activities for the next 7 days based on the new Weekly TSS
        for (let i = 0; i < days.length; i++) { // Start from the second day (index 1)
            const day = days[i];
            for (let j = 0;j < day.activities.length;j++){
                const newTSS = getSumTSS(day.activities[j], user) + (getSumTSS(day.activities[j], user) * loadAdjustmentValue);
                day.activities[j] = updateAiActivity(day.activities[j].type, options, newTSS);
            }
        }
        
        // Set the adjusted days back
        setNext7Days(days);
        // Set Changes
        const newChangesObject:ChangesObject = {
            avgEffort:averageEffort,
            newTSS:newWeeklyTSS,
            adjustedTSSValue:loadAdjustmentValue
        }
        setAiChanges(newChangesObject);
        setLoading(false);
    }
    

    const updateAiActivity = (type:string, options:any, newTSS:number) => {
        var activity:Activity;

        activity = updateWorkout(newTSS, options, type, user, workoutOptions[type as ActivityType]);
        // for (var j = 0;j<activity.steps.length;j++){
        //     let step = activity.steps[j]
        //     activity.steps[j] = extractActivityStep(step.stepDescription, step.stepName)
        // }
        return activity;
    };

    // Function to handle selecting an activity
    const handleDayClick = (day: Day) => {
        setSelectedActivityIndex(0);
        setSelectedDay(day);
        convertIntensityRangeToZones(0, 0)
    };

    // Function to handle going back to main view
    const handleBackButtonClick = () => {
        setSelectedDay(null);
        setSelectedActivityIndex(0);
    };

    const finishReview = () => {
        adaptNext7Days();
        setReviewingActivity(null);
        setReviewStage(0);
        setSelectedDay(null);
        setSelectedActivityIndex(0);
    }

    async function handleSubmitClick(skipped=false){
        try {
            // Clone the user object to avoid direct mutation
            const newUser = { ...user };

            if (selectedDay !== null && reviewingActivity){
                const reviewId = `review_${user.currentPlan.planId}${getWeekNumber(selectedDay)}${selectedDay.day}${selectedDay.activities[selectedActivityIndex].type}`
                const foundReview = newUser.reviews.find(review=>review._id === reviewId)
                if (foundReview){
                    // Update
                    if (skipped){
                        newUser.reviews[newUser.reviews.indexOf(foundReview)] = {
                            _id:foundReview._id,
                            date:getDateFromDay(user.userPlanStartDate.getTime(), getWeekNumber(selectedDay), user.currentPlan.content[getWeekNumber(selectedDay)].days.indexOf(selectedDay)),
                            day:next7Days.indexOf(selectedDay),
                            results:{
                                duration:-1,
                                distance:-1,
                                pace:-1,
                                power:-1,
                                hr:-1,
                                effort:-1,
                                sleepQuality:-1
                            },
                            activity:reviewingActivity
                        }
                    }else{
                        newUser.reviews[newUser.reviews.indexOf(foundReview)] = {
                            _id:foundReview._id,
                            date:getDateFromDay(user.userPlanStartDate.getTime(), getWeekNumber(selectedDay), user.currentPlan.content[getWeekNumber(selectedDay)].days.indexOf(selectedDay)),
                            day:next7Days.indexOf(selectedDay),
                            results:{
                                duration:duration,
                                distance:distance,
                                pace:pace,
                                power:power,
                                hr:heartRate,
                                effort:effort,
                                sleepQuality:sleepQuality
                            },
                            activity:reviewingActivity
                        }
                    }

                }else{
                    // Add
                    if (skipped){
                        newUser.reviews.push({
                            _id:reviewId,
                            date:getDateFromDay(user.userPlanStartDate.getTime(), getWeekNumber(selectedDay), user.currentPlan.content[getWeekNumber(selectedDay)].days.indexOf(selectedDay)),
                            day:next7Days.indexOf(selectedDay),
                            results:{
                                duration:-1,
                                distance:-1,
                                pace:-1,
                                power:-1,
                                hr:-1,
                                effort:-1,
                                sleepQuality:-1
                            },
                            activity:reviewingActivity
                        });
                    }else{
                        newUser.reviews.push({
                            _id:reviewId,
                            date:getDateFromDay(user.userPlanStartDate.getTime(), getWeekNumber(selectedDay), user.currentPlan.content[getWeekNumber(selectedDay)].days.indexOf(selectedDay)),
                            day:next7Days.indexOf(selectedDay),
                            results:{
                                duration:duration,
                                distance:distance,
                                pace:pace,
                                power:power,
                                hr:heartRate,
                                effort:effort,
                                sleepQuality:sleepQuality
                            },
                            activity:reviewingActivity
                        });
                    }

                }
            
                // Update the user data via API
                await Api.updateUser(user._id, newUser);
                if (skipped){
                    setReviewStage(6)
                }else{
                    nextStage()

                }
                    
            }


        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
    }

    const generateMarks = () => {
        const marks = [];
        for (let seconds = 60; seconds <= 540; seconds += 5) {
            marks.push({ value: seconds });
        }
        return marks;
    };

    const marks = generateMarks();

    // const sliderStyling = {
    //     color: colorPalette.steelBlue, // or use a hex code like '#1976d2'
    //     height: 16, // height of the slider track
    //     width: '60vw',
    //     '& .MuiSlider-thumb': {
    //         height: 24, // size of the thumb
    //         width: 24,
    //         backgroundColor: '#fff',
    //         border: '2px solid currentColor',
    //         '&:hover': {
    //             boxShadow: '0 0 0 8px rgba(0,0,0,0.16)',
    //         },
    //         '&.Mui-active': {
    //             boxShadow: '0 0 0 14px rgba(0,0,0,0.16)',
    //         },
    //     },
    //     '& .MuiSlider-rail': {
    //         height: 16,
    //     },
    //     '& .MuiSlider-track': {
    //         height: 16,
    //     },
    // }
    const sliderStyling = {
        color: 'gray', // or use a hex code like '#1976d2'
        height: 48, // height of the slider track
        width: '60vw',
        borderRadius:'1px',
        margin:'5px 20px',
        '& .MuiSlider-thumb': {
            height: 48, // size of the thumb
            width: 6,
            backgroundColor: '#fff',
            borderRadius:'0px',
            // border: '2px solid currentColor',
            '&:hover': {
                boxShadow: 0,
                backgroundColor:colorPalette.steelBlue
            },
            '&.Mui-active': {
                boxShadow: 0,
                backgroundColor:colorPalette.steelBlue
            },
        },
        '& .MuiSlider-rail': {
            height: 48,
        },
        '& .MuiSlider-track': {
            height: 48,
        },
    }

    function msToMinPerKm(speed: number): string {
        if (speed > 0){
            // 1 km = 1000 meters
            // Calculate time in seconds to run 1 km
            const timeInSeconds = 1000 / speed;
            
            // Convert time to minutes and seconds
            const minutes = Math.floor(timeInSeconds / 60);
            const seconds = Math.round(timeInSeconds % 60);
            
            // Format minutes and seconds as MM:SS
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');
            
            return `${formattedMinutes}:${formattedSeconds}`;
        }else{
            return `00:00`
        }

    }

    function convertIntensityRangeToZones(lowerIntensity: number, upperIntensity: number) {
        var finalZone = []
        const zones = getZones('Run');
        const lowerZone = Math.round(lowerIntensity);
        const upperZone = Math.round(upperIntensity);

        // 1
        var integerPart  = Math.floor(lowerIntensity);
        var decimalPart = lowerIntensity - integerPart;
        var integerPartZone = timeStringToSeconds(zones[integerPart].pace[0]);
        var decimalPartZone = Math.abs(timeStringToSeconds(zones[integerPart].pace[1]) - timeStringToSeconds(zones[integerPart].pace[0])) * decimalPart;
        var pace = secondsToPaceString(Math.round(integerPartZone + decimalPartZone));
        finalZone.push(pace)

        // 2
        integerPart  = Math.floor(upperIntensity);
        decimalPart = upperIntensity - integerPart;
        integerPartZone = timeStringToSeconds(zones[integerPart].pace[0]);
        decimalPartZone = Math.abs(timeStringToSeconds(zones[integerPart].pace[1]) - timeStringToSeconds(zones[integerPart].pace[0])) * decimalPart;
        pace = secondsToPaceString(Math.round(integerPartZone + decimalPartZone));
        finalZone.push(pace)

        return {
            lowerZone:{
                zone:zones[lowerZone].zone,
                pace:finalZone,
                hr:zones[lowerZone].hr
            },
            upperZone:{
                zone:zones[upperZone].zone,
                pace:finalZone,
                hr:zones[upperZone].hr
            }
        }
        
    }
    
    function getZones(activity:string){
        const bestEfforts = user.bestEfforts;
        const best5kEffort = bestEfforts.find(effort => effort.id === 0);

        if (best5kEffort){
            const FTP = timeStringToSeconds(best5kEffort.time) * 1.05
            const FTHR = best5kEffort.avgHeartRate * 0.93
            const distance = 5;


            var zones:Array<Zone> = [
                {
                    zone:'Zone 1',
                    pace:[secondsToTimeString(Math.round(FTP/distance * 1.4)), secondsToTimeString(Math.round(FTP/distance * 1.29))],
                    hr:[Math.round(FTHR * 0.75),Math.round(FTHR * 0.85)]
                },
                {
                    zone:'Zone 2',
                    pace:[secondsToTimeString(Math.round(FTP/distance * 1.29)), secondsToTimeString(Math.round(FTP/distance * 1.14))],
                    hr:[Math.round(FTHR * 0.85),Math.round(FTHR * 0.89)]                },
                {
                    zone:'Zone 3',
                    pace:[secondsToTimeString(Math.round(FTP/distance * 1.14)), secondsToTimeString(Math.round(FTP/distance * 1.06))],
                    hr:[Math.round(FTHR * 0.89),Math.round(FTHR * 0.94)]                },
                {
                    zone:'Zone 4',
                    pace:[secondsToTimeString(Math.round(FTP/distance * 1.06)), secondsToTimeString(Math.round(FTP/distance * 1.01))],
                    hr:[Math.round(FTHR * 0.94),Math.round(FTHR * 0.99)]                },
                {
                    zone:'Zone 5',
                    pace:[secondsToTimeString(Math.round(FTP/distance * 1.01)), secondsToTimeString(Math.round(FTP/distance * 0.97))],
                    hr:[Math.round(FTHR * 0.99),Math.round(FTHR * 1.06)]                },
                {
                    zone:'Zone 6',
                    pace:[secondsToTimeString(Math.round(FTP/distance * 0.97)), secondsToTimeString(Math.round(FTP/distance * 0.9))],
                    hr:[Math.round(FTHR * 1.06), Math.round(FTHR * 1.2)]                },
            ]

            return zones
        }else{
            return [];
        }
    }

    function timeStringToSeconds(timeString:string) {
        // alert(timeString)
        const timeParts = timeString.split(':');
        const minutes = parseInt(timeParts[0], 10);
        const seconds = parseInt(timeParts[1], 10);
        return (minutes * 60) + seconds;
    }

    const paceStringToSeconds = (value: number): string => {
        const minutes = Math.floor(value / 60);
        const seconds = value % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    function secondsToPaceString(totalSeconds:number) {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
    
        const padWithZero = (num:number) => num.toString().padStart(2, '0');
    
        return `${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(seconds)}`;
    }

    function getWeekNumber(day:Day){
        for (var i =0;i<userPlan.content.length;i++){
            if (userPlan.content[i].days.includes(day)){
                // alert(week.weekNumber)
                return i;

            }
        }

        return 0
    }

    function getWeekDayName(dateString: string, week: number, day: number): string {
        // Parse the input dateString to create a Date object
        const date = new Date(dateString);
        
        if (isNaN(date.getTime())) {
            throw new Error("Invalid date string");
        }
    
        // Calculate the total days to add
        const totalDaysToAdd = (week * 7) + day;
    
        // Add the total days to the date
        date.setDate(date.getDate() + totalDaysToAdd);
    
        // Get the day of the week as a number (0 for Sunday, 1 for Monday, ...)
        const dayOfWeek = date.getDay();
    
        // Map the day numbers to their names
        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    
        // Return the name of the day
        return dayNames[dayOfWeek];
    }

    function getCalendarDay(dateString: string, week: number, day: number) {
        // Convert initial date string to a Date object
        const startDate = new Date(dateString);
        // Number of days to add (corrected by removing +1)
        const daysToAdd = week * 7 + day;
    
        // Clone the initial date to avoid mutating it directly
        const newDate = new Date(startDate);
    
        // Add the correct number of days
        newDate.setDate(newDate.getDate() + daysToAdd);
    
        // Get the day of the month from the new date
        const dayOfMonth = newDate.getDate();
        
        // Format the day to include "th", "st", "nd", "rd" suffixes
        function getDayWithSuffix(day: number) {
            if (day >= 11 && day <= 13) {
                return `${day}th`;
            }
            switch (day % 10) {
                case 1:
                    return `${day}st`;
                case 2:
                    return `${day}nd`;
                case 3:
                    return `${day}rd`;
                default:
                    return `${day}th`;
            }
        }
        
        const formattedDay = getDayWithSuffix(dayOfMonth);
        return formattedDay;
    }

    function getDateFromDay(startDate: number, week: number, day: number): number {
        // Calculate the total number of days to add
        const totalDaysToAdd = (week) * 7 + (day);
    
        // Create a new Date object from the startDate
        const start = new Date(startDate);
    
        // Add the total days to the start date
        start.setDate(start.getDate() + totalDaysToAdd);
    
        // Return the date in milliseconds
        return start.getTime();
    }
    
    

    function isDayComplete(day:Day){
        for (const activity of day.activities){
            const findReview = user.reviews.find(review=> review._id === `review_${user.currentPlan.planId}${getWeekNumber(day)}${day.day}${activity.type}`);

            if (!findReview){
                return false
            }
        }
        return true
    }

    function getUserStats(){
        let stats = []

        // CONSISTENCY STAT
        stats.push({
            title:'Consistency',
            value:0.5
        })
        return stats;
    }

    function isDayReviewed(day: Day) {
        // Calculate the day of the week index from the day string
        const dayIndex = user.currentPlan.content[getWeekNumber(day)].days.findIndex(d=>d.day===day.day);
        
        // Calculate the date of the day based on the startDate and getWeekNumber(day)
        const dayDate = new Date(user.userPlanStartDate.toString());
        dayDate.setDate(dayDate.getDate() + getWeekNumber(day) * 7 + dayIndex);
    
        // Get today's date without time for accurate comparison
        const today = new Date();
        today.setHours(0, 0, 0, 0);
    
        // Check if all activities are reviewed
        for (const activity of day.activities) {
            const findReview = user.reviews.find(review => review._id === `review_${user.currentPlan.planId}${getWeekNumber(day)}${day.day}${activity.type}`);
    
            if (!findReview) {
                // If the day has passed and an activity is not reviewed, return false
                if (today > dayDate) {
                    return false;
                }
            }
        }
    
        return true;
    }

    function milliSecondsToDate(value: number): string {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if needed
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const month = monthNames[date.getMonth()]; // Get the month name
        const year = date.getFullYear(); // Get the year
    
        return `${day} ${month} ${year}`;
    }

    function formatMinutes(value:number){
        if (value >= 0) {
            // Calculate hours and remaining minutes
            let hours = Math.floor(value / 60);
            let minutes = Math.floor(value % 60);
            let seconds = Math.round((value % 1) * 60);
    
            // Format hours, minutes, and seconds
            let hoursStr = hours > 0 ? hours + " hour" + (hours !== 1 ? "s" : "") + " " : "";
            let minutesStr = minutes > 0 ? minutes + " minute" + (minutes !== 1 ? "s" : "") + " " : "";
            let secondsStr = seconds > 0 ? seconds + " second" + (seconds !== 1 ? "s" : "") : "";
    
            // Combine the formatted string
            return (hoursStr + minutesStr + secondsStr).trim();
        } else {
            return "Invalid value";
        }
    }

    function getDayIndex() {
        const currentDay = new Date().getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
        return (currentDay === 0) ? 6 : currentDay - 1; // Adjust to make Monday 0, Tuesday 1, ..., Sunday 6
    }
    
    console.log(getDayIndex()); // Example usage
    

    function getActivityColor(activityType:string){
        switch (activityType){
            case 'Run':
                return colorPalette.steelBlue;  // Soft Red
            case 'Strength':
                return colorPalette.steelBlue;  // Light Pink
            case 'Swim':
                return colorPalette.steelBlue;  // Dark Turquoise
            case 'Bike':
                return colorPalette.steelBlue;  // Medium Purple
            case 'Other':
                return colorPalette.steelBlue;  // Gold
            default:
                return colorPalette.steelBlue;  // Steel Blue
        }
    }

    function millisecondsToDateString(milliseconds:number) {
        const date = new Date(milliseconds);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based in JavaScript
        const year = date.getFullYear();
        
        return `${day}/${month}/${year}`;
      }

    function getPastTrainingData(numberOfDays:number){
        var pastDays:Day[] = [];
        const reviews = user.reviews;
        for (const review of reviews){
            if (review.results.effort>=0){
                // let updatedReview:Review = review;
                // updatedReview.activity.steps = [{
                //     stepName:'',
                //     stepDescription:'',
                //     stepType:review.activity.type,
                //     stepDistance:review.results.distance,
                //     stepDuration:review.results.duration * 60,
                //     stepExercise:'',
                //     stepIntensity:review.results.effort,
                //     stepReps:-1,
                //     stepSets:-1,
                //     stepZone:Math.floor(review.results.effort/2)
                // }]
                const dayToAdd:Day = {day:millisecondsToDateString(review.date), activities:[review.activity]}
                pastDays.push(dayToAdd);
            }
        }
        return pastDays;
    }
    
    if (user.currentPlan){
        if (!loading){
            return (
                <div className="dashboard-page" style={{backgroundColor:'#f1f1f1'}}>
                    <div className="dashboard-view-container" style={{backgroundColor:'#f1f1f1'}}>
                        {showCalendar && 
                            <div className="side-panel-container"> 
                                <div className="expand-arrow-container" onClick={toggleCalendar}>
                                    <FontAwesomeIcon className="expand-arrow" icon={showCalendar ? faChevronLeft : faGripLinesVertical}></FontAwesomeIcon>
                                </div>
                            </div>
                        }
                        {showRightPanel && 
                            <div className="side-panel-container"> 
                                <div className="expand-arrow-container" onClick={()=>toggleRightPanel()}>
                                    <FontAwesomeIcon className="expand-arrow" icon={showRightPanel ? faChevronLeft : faGripLinesVertical}></FontAwesomeIcon>
                                </div>
                            </div>
                        }

                        {showCalendar ? 
                            (
                                <div className={`main-panel-container`} style={{backgroundColor:'#f1f1f1'}}>

                                </div>
                            )
                        :
                        showRightPanel ? 
                            (
                                <div className={`main-panel-container`} style={{backgroundColor:'#f1f1f1'}}>

                                </div>
                            )
                            :
                            (
                                <div className={`main-panel-container`} style={{flexDirection:'row',backgroundColor:'#f1f1f1',display:'flex', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                    <div style={{display:'flex', flex:14, flexDirection:'column', backgroundColor:'#f1f1f1'}}>
                                        <div className="top-panel" style={{paddingLeft:'20px',}}>
                                        <div className="top-panel-streak" style={{scale:'1'}} onClick={toggleCalendar}>
                                                <FontAwesomeIcon className="streak-icon" icon={faCalendarDays}></FontAwesomeIcon>
                                                <label className="streak-label" style={{fontWeight:'bold', fontStyle:'italic', fontSize:'20px'}}>WEEKLY PLAN</label>
                                            </div>

                                        <div style={{display:'flex', flex:2, justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column', width:'100%',}}>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    {/* <FontAwesomeIcon className="calendar-icon" style={{marginTop:'20px'}} icon={faCalendarDay}></FontAwesomeIcon> */}
                                                    <label className="streak-label" style={{fontWeight:'bold', fontStyle:'italic',fontSize:'1.5vw', color:'#2f3b4a', marginTop:'0'}}>OUR PLAN FOR YOU TODAY</label>
                                                </div>
                                                <div style={{display:'flex', flexDirection:'column',justifyContent:'flex-start', alignItems:'center', marginTop:'10px', width:'100%'}}>
                                                    {getNext7Days()[getDayIndex()].activities.map((activity, index)=>(
                                                        <div className="activity-button" style={{backgroundColor:index%2===0?'#fafafa':'#f6f6f6', padding:'30px', marginLeft:'10px', marginTop:'5px', borderRadius:'5px', width:'94%', flexDirection:'column', display:'flex'}} onClick={()=>{setShowCalendar(true);setSelectedDay(getNext7Days()[getDayIndex()])}}>
                                                            <label style={{color:colorPalette.steelBlue, fontStyle:'italic', fontWeight:'bold', pointerEvents:'none'}}>{activity.title.toUpperCase()}</label>    
                                                            <label style={{color:colorPalette.steelBlue, fontSize:'15px', pointerEvents:'none'}}>{activity.description}</label>    
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>


                                        </div>
                                        <div style={{display:'flex', flex:3,paddingLeft:'20px', paddingRight:'20px', width:'100%', height:'auto',flexDirection:'column', backgroundColor:'#f1f1f1',justifyContent:'flex-start', alignItems:'flex-start'}}>
                                            <label style={{color:'#565656', marginBottom: '10px', fontSize:'1.5vw', fontWeight:'bold'}}>Training Overview</label>
                                            {user.reviews.length > 0 ?
                                                <ChartComponent bgc={'transparent'} weekData={getPastTrainingData(7).reverse()} user={user} mw={100} mh={40}></ChartComponent>
                                            :
                                            <div className="history-item" style={{marginBottom:'2vh', height:'10vh', width:'100%', justifyContent:'center', alignItems:'center', display:'flex'}}>
                                                <label style={{color:'#565656', margin: '10px 10px', fontSize:'1.25vw', fontWeight:'normal'}}>No Training Data Yet</label>
                                            </div>
                                            }
                                            <label style={{color:'#565656', margin: '10px 10px', marginTop:'30px', fontSize:'1.5vw', backgroundColor:'#f1f1f1',fontWeight:'bold'}}>Training History</label>
                                            {user.reviews.length <= 0 && (
                                                <div className="history-item" style={{marginBottom:'2vh', height:'10vh', width:'100%', justifyContent:'center', alignItems:'center', display:'flex'}}>
                                                    <label style={{color:'#565656', margin: '10px 10px', fontSize:'1.25vw', fontWeight:'normal'}}>No Activity History Yet</label>
                                                </div>
                                            )}
                                            {user.reviews.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map((historyItem: Review, index: number) => (
                                                
                                                historyItem.results.effort >= 0 ?
                                                <div className="history-item" style={{marginBottom:'2vh', background:index % 2 === 0 ? '#ffffff':'#fafafa'}}>
                                                    <div style={{display:'flex', width:'100%', height:'auto',flex:1}}>
                                                        <div style={{display:'flex', flex:1}}>
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold'}}>{historyItem.activity.type}</label>
                                                        </div>
                                                        <div style={{display:'flex', justifyContent:'flex-end', flex:1}}>
                                                            <label style={{color:'#565656', margin: '10px 15px', fontSize:'1vw', fontWeight:'bold'}}>{milliSecondsToDate(historyItem.date)}</label>
                                                        </div>
                                                    </div>
                                                    <div style={{display:'flex', width:'100%', height:'auto',flex:1, marginLeft:'1.5vw', marginRight:'1.5vw',alignItems:'center'}}>
                                                        {historyItem.results.duration > 0 ?
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Duration (Minutes)</label>   
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.duration}</label> 
                                                        </div>
                                                        :                                                    
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Distance (KM)</label>   
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.distance}</label> 
                                                        </div>
                                                        }

                                                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                                                        {historyItem.results.pace > 0 ?
                                                            <div style={{display:'flex', flexDirection:'column'}}>
                                                                <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Pace</label>   
                                                                <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{secondsToPaceString(historyItem.results.pace)}</label> 
                                                            </div>
                                                            :
                                                            <div style={{display:'flex', flexDirection:'column'}}>
                                                                <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Avg Power</label>   
                                                                <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.power}</label> 
                                                            </div>
                                                        }
                                                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>HR</label>   
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.hr}</label> 
                                                        </div>
                                                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Effort</label>   
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.effort} / 10</label> 
                                                        </div>
                                                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Sleep Quality</label>   
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.sleepQuality} / 10</label> 
                                                        </div>

                                                    </div>
                                                </div>
                                                :
                                                <div className="history-item" style={{marginBottom:'2vh', backgroundColor:colorPalette.softRed}}>
                                                    <div style={{display:'flex', width:'100%', height:'auto',flex:1}}>
                                                        <div style={{display:'flex', flex:1}}>
                                                            <label style={{color:'#f1f1f1', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold'}}>{historyItem.activity.type} (Missed)</label>
                                                        </div>
                                                        <div style={{display:'flex', justifyContent:'flex-end', flex:1}}>
                                                            <label style={{color:'#f3f3f3', margin: '10px 15px', fontSize:'1vw', fontWeight:'bold'}}>{milliSecondsToDate(historyItem.date)}</label>
                                                        </div>
                                                    </div>
                                                    <div style={{display:'flex', width:'100%', height:'auto',flex:1, marginLeft:'1.5vw', marginRight:'1.5vw',alignItems:'center'}}>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#f1f1f1', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Duration</label>   
                                                            <label style={{color:'white', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                                                        </div>
                                                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#f1f1f1', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Pace</label>   
                                                            <label style={{color:'white', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                                                        </div>
                                                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#f1f1f1', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>HR</label>   
                                                            <label style={{color:'white', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                                                        </div>
                                                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#f1f1f1', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Effort</label>   
                                                            <label style={{color:'white', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                                                        </div>
                                                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#f1f1f1', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Sleep Quality</label>   
                                                            <label style={{color:'white', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                                                        </div>

                                                    </div>
                                                </div>
                                            ))}
                                            {/* <label style={{color:'#565656', marginBottom: '10px', fontSize:'1.5vw', fontWeight:'bold'}}>Performance Analysis</label>
                                            {user.reviews.length > 0 ?
                                               <div className="history-item" style={{marginBottom:'2vh', height:'10vh', width:'100%', justifyContent:'center', alignItems:'center', display:'flex'}}>
                                                    {getUserStats().map((stat)=>(
                                                        <div>
                                                            <label>{stat.title}</label>
                                                            <label>{stat.value}</label>
                                                        </div>

                                                    ))}
                                                </div>
                                            :
                                                <div className="history-item" style={{marginBottom:'2vh', height:'10vh', width:'100%', justifyContent:'center', alignItems:'center', display:'flex'}}>
                                                    <label style={{color:'#565656', margin: '10px 10px', fontSize:'1.25vw', fontWeight:'normal'}}>No Training Data Yet</label>
                                                </div>
                                            } */}
                                        </div>
                                    </div>
                                    {/* <div style={{display:'flex', flex:1, flexDirection:'column', height:'100%', borderRadius:'5px',marginTop:'9vh',backgroundColor:'white',justifyContent:'flex-start', alignItems:'center'}}>
                                        <label style={{fontSize:'1.5vw', fontWeight:'bold', fontStyle:'italic',color:colorPalette.steelBlue, marginTop:'15px'}}>COMMUNITY HIGHLIGHTS</label>
                                        <div style={{display:'flex', width:'100%', height:'100%', justifyContent:'center', alignItems:'flex-start'}}>
                                            <label style={{fontSize:'1vw',color:colorPalette.steelBlue, marginTop:'15px'}}>No Highlights To Show Yet</label>
                                        </div>
                                    </div> */}
                                    <div style={{
                                        display: 'flex', 
                                        flex: 1, 
                                        flexDirection: 'column', 
                                        height: '100vh', 
                                        borderTopLeftRadius: '8px', 
                                        paddingBottom:'10px',
                                        marginTop: '9vh',
                                        backgroundColor: 'white',
                                        justifyContent: 'flex-start', 
                                        alignItems: 'center',
                                    }}>
                                        <div className="icon-button" style={{borderTopRightRadius:0, borderTopLeftRadius:'5px', borderBottomRightRadius:0, borderBottomLeftRadius:0}}>
                                        <FontAwesomeIcon onClick={()=>toggleRightPanel('checkin')} className="icon-button-icon" icon={faUserDoctor}/>
                                            <label style={{fontSize:'10px', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>CHECK IN</label>

                                        </div>
                                        <div onClick={()=>toggleRightPanel('tests')} className="icon-button" style={{borderRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon" icon={faListCheck}/>
                                            <label style={{fontSize:'10px', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>TESTS</label>
                                        </div>
                                        <div className="icon-button" style={{borderRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon" icon={faSmoking}/>
                                            <label style={{fontSize:'10px', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>SMOKING</label>
                                        </div>
                                        <div className="icon-button" style={{borderRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon" icon={faBeer}/>
                                            <label style={{fontSize:'10px', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>ALCOHOL</label>
                                        </div>
                                        <div className="icon-button" style={{borderRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon" icon={faBowlFood}/>
                                            <label style={{fontSize:'10px', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>NUTRITION</label>

                                        </div>
                                        <div className="icon-button" style={{borderTopRightRadius:0, borderTopLeftRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:'5px'}}>
                                            <FontAwesomeIcon className="icon-button-icon" icon={faBed}/>
                                            <label style={{fontSize:'10px', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>REST</label>

                                        </div>
                                    </div>

                                </div>


                            )
                        }


                    </div>
                    {/* CALENDAR */}
                    <div className={`calendar-container ${showCalendar ? 'show' : ''}`}>
                        {/* CALENDAR VIEW */}
                        {selectedDay ? (
                            !reviewingActivity ?
                                (
                                selectedDay.day === "Overview" ? 
                                <div className='card-enlarged' style={{backgroundColor:'#ffffff66',height:'100%', width:'100%', alignItems:'flex-start', flexDirection:'row',}}>
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:`#2f3b4a`, width:'100%', height:'8vh', borderRadius:'0'}}>
                                        <div style={{backgroundColor:'transparent',  display:'flex', alignItems:'center', width:'100%'}}>
                                            <FontAwesomeIcon className="back-button" style={{width:'2vw', height:'2vw', marginLeft:'0.75vw', padding:'5px'}} onClick={handleBackButtonClick} icon={faArrowLeft}></FontAwesomeIcon>
                                        </div>
                                    </div>

                                    <div style={{display:'flex', width:'100%', height:'90%', flexDirection:'row', alignItems:'flex-start'}}>
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:2, flexDirection:'column', alignItems:'flex-start', justifyContent:'center'}}>
                                            <label style={{color:'#565656', marginTop: '10px', marginLeft:'1vw', fontSize:'1.5vw', fontWeight:'bold'}}>{`Training Load Overview (Week ${getWeekNumber(next7Days[0])+1})`}</label>
                                            <ChartComponent mh={80} mw={100} weekData={next7Days} user={user}></ChartComponent>
                                        </div>
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:1, backgroundColor:'#f1f1f1', flexDirection:'column', alignItems:'center'}}>
                                            <div style={{display:'flex', width:'100%'}}>
                                            <label style={{color:'#565656', marginTop: '10px', marginLeft:'1vw', fontSize:'1.5vw', fontWeight:'bold'}}>AI Changes</label>
                                            </div>

                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:1}}>
                                                    <label style={{fontSize:'1vw', color:`#454545`, backgroundColor:'#e1e1e1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Average Effort Offset`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1vw', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{user.reviews.length > 0 ? `${Math.round(aiChanges.avgEffort * 10)/10}` : `No Data Yet`}</label>
                                                </div>
                                            </div>
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:1}}>
                                                    <label style={{fontSize:'1vw', color:`#454545`, backgroundColor:'#e1e1e1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Calculated Adjustment`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1vw', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{user.reviews.length > 0 ? `${Math.round(aiChanges.adjustedTSSValue * 100)}%` : `No Data Yet`}</label>
                                                </div>
                                            </div>
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:1}}>
                                                    <label style={{fontSize:'1vw', color:`#454545`, backgroundColor:'#e1e1e1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Adjusted Weekly TSS`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1vw', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{`${Math.round(aiChanges.newTSS)}`}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='card-enlarged' style={{overflowY:'scroll',backgroundColor:'#ffffff66', minHeight:'75vh', width:'100%', display: 'flex', flexDirection: 'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:`${colorPalette.default}88`, width:'100%', height:'8vh', borderRadius:'0'}}>
                                        <div style={{backgroundColor:'transparent',  display:'flex', alignItems:'center', width:'100%'}}>
                                            <FontAwesomeIcon className="back-button" style={{width:'2vw', height:'2vw', marginLeft:'0.75vw', padding:'5px'}} onClick={handleBackButtonClick} icon={faArrowLeft}></FontAwesomeIcon>
                                        </div>
                                        <div style={{backgroundColor:'transparent', display:'flex', justifyContent:'flex-end', alignItems:'center', width:'100%',}}>
                                            {/* <label style={{fontSize:'1vw', marginTop:'5px', fontWeight:'bold', marginRight:'5px'}}>Review</label> */}
                                            <FontAwesomeIcon className="download-button" onClick={()=>setReviewingActivity(selectedDay.activities[selectedActivityIndex])} style={{width:'2.5vw', height:'2.5vw', marginRight:'1vw', padding:'5px'}}  icon={faRobot}></FontAwesomeIcon>
                                        </div>
                                    </div>

                                    <div style={{display:'flex', justifyContent:'flex-start',justifyItems:'flex-start',flexDirection:'row', backgroundColor:'transparent', width:'100%', height:'auto'}}>
                                        <div style={{display:'flex',flex:1,backgroundColor:'transparent', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'80%', backgroundColor:`${colorPalette.default}aa`, display:'flex', justifyContent:'center', alignItems:"center"}}>
                                                <label style={{fontSize:'1.25vw', fontWeight:'bold', padding:'2vw', maxLines:1, overflowY:'hidden', fontStyle:'italic'}}>{selectedDay.activities[selectedActivityIndex].title.toUpperCase()}</label>
                                            </div>
                                        </div>
                                        <div style={{display:'flex',flex:3,backgroundColor:'transparent', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'80%', backgroundColor:`${colorPalette.default}dd`, display:'flex', textAlign:'start', justifyContent:'center', alignItems:'center'}}>
                                            <label style={{padding:'15px', fontSize:'1.125vw', textAlign:'justify', overflowY:'scroll'}}>{selectedDay.activities[selectedActivityIndex].description}</label>
                                            </div>
                                        </div>

                                    </div>
                                    
                                    <div style={{display:'flex', justifyContent:'flex-start',justifyItems:'flex-start',flexDirection:'row', backgroundColor:'#f1f1f1', width:'100%', height:'53vh', overflow:'hidden'}}>
                                    {viewportSize.width/viewportSize.height > 1.25 && 
                                    
                                            <div style={{display:'flex',flex:1,backgroundColor:'#f1f1f1', justifyContent:'center', alignItems:'center'}}>
                                                <div style={{width:'8vw', height:'100%', backgroundColor:`#f1f1f1`,  flexDirection:'column', display:"flex", justifyContent:'center', alignItems:'center'}}>
                                                        {selectedDay.activities.map((activity:Activity, index:number)=>(
                                                        <div className="section-buttons" onClick={()=>setSelectedActivityIndex(index)} 
                                                            style={{
                                                                backgroundColor:selectedActivityIndex===index?getActivityColor(activity.type):`${colorPalette.default}88`,
                                                                borderStyle:'solid',
                                                                borderWidth:'0px',
                                                                borderRightWidth:selectedActivityIndex===index?'5px':'3px',
                                                                borderColor:selectedActivityIndex===index?'#ffffff':'#ffffff77'
                                                                }}>
                                                            <FontAwesomeIcon className="button-icon" icon={activity.type==='Run'? faRunning: activity.type === 'Rest' ? faBed : activity.type==='Swim'?faSwimmer:activity.type==='Cycle'?faBicycle:activity.type==='Strength'?faDumbbell:activity.type==='Day Off'?faBan:faPerson}></FontAwesomeIcon>
                                                        </div>
                                                        ))}
                                                </div>
                                        </div>
                                        }
                                        {viewportSize.width/viewportSize.height > 1.25 && 
                                        <div style={{display:'flex',flex:8,backgroundColor:`#f1f1f1`, justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                            {selectedDay.activities[selectedActivityIndex].steps.length > 0 ?
                                            <div style={{width:'100%', height:'100%', maxWidth:'100%',backgroundColor:`${colorPalette.default}33`, display:'flex', paddingLeft:'2vw',paddingBottom:'2vw',justifyContent:'flex-start', alignItems:'flex-end'}}>
                                                {/* {getWorkoutLabel(selectedDay.activities[selectedActivityIndex])} */}
                                                <div style={{ flexDirection: 'row', display:'flex', flex:3,justifyContent:'center', alignContent:'center', justifyItems:'center', alignItems:'flex-end', backgroundColor:'transparent', borderWidth: '3px',
                                                borderColor: '#ffffff',
                                                borderStyle: 'solid',
                                                borderTopWidth:'0',
                                                overflowX:'hidden',
                                                maxWidth:'80vw',
                                                // maxHeight:'45vh',
                                                minHeight:'45vh',
                                                marginTop:'10vh',
                                                borderRightWidth:'0',
                                                width:'95%',
                                                // maxWidth:'50vw',
                                                padding:'5px 5px 0px 0px'}}>
                                            <div style={{display:'flex', flexDirection:'row', alignItems:'flex-end', justifyContent:'center'}}>
                                            {selectedDay.activities[selectedActivityIndex].steps.map((step:ActivityStep, index) => (
                                                <div style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
                                                {step.stepSets && step.stepSets > 0? 
                                                    <div key={`step-${index}`} 
                                                        className="tooltip"
                                                        style={{
                                                            backgroundColor:colorPalette.steelBlue,
                                                            opacity:1,
                                                            maxWidth:`calc(50vw/${selectedDay.activities[selectedActivityIndex].steps.length})`,
                                                            width:`calc(${step.stepSets}*2vw)`, 
                                                            height: step.stepReps && step.stepReps > 0 ? `calc(${step.stepReps}*1vw)` : step.stepDuration && step.stepDuration > 0 ? `calc(${step.stepDuration/60*step.stepSets}*1vw)` : '1vw',
                                                            marginRight:'2px',
                                                            justifyContent:'flex-start', 
                                                            display:'flex', 
                                                            alignContent:'flex-start'
                                                        }}>
                                                        <span style={{padding:'5px 10px', zIndex:100}} className="tooltiptext">{step.stepDescription}</span>
                                                    </div>
                                                :
                                                <div key={`step-${index}`} 
                                                    className="tooltip"
                                                    style={{
                                                        backgroundColor:colorPalette.steelBlue,
                                                        opacity:1,
                                                        maxWidth:`calc(50vw/${selectedDay.activities[selectedActivityIndex].steps.length})`,
                                                        width:step.stepDuration && step.stepDuration > -1 ? `calc(${step.stepDuration/800}*8vw)`: step.stepDistance && step.stepDistance > -1 ? `calc(${step.stepDistance/5}* 8vw)`:'2vw', 
                                                        height: step.stepIntensity && step.stepIntensity > 0 ? `calc(${step.stepIntensity} * 2vh` : step.stepZone && step.stepZone > 0 ? `calc(${step.stepZone} * 4vh` : '1vh',
                                                        marginRight:'2px',
                                                        justifyContent:'flex-start', 
                                                        display:'flex', 
                                                        alignContent:'flex-start',
                                                    }}>
                                                        <span style={{padding:'5px 10px'}} className="tooltiptext">{step.stepDescription}</span>
                                                </div>
                                                }
                                                </div>
                                                )
                                            )}
                                            </div>    
                                        </div>
                                            </div>
                                            :
                                            <div style={{width:'100%', height:'100%', backgroundColor:`#f1f1f1`, display:'flex',justifyContent:'center', alignItems:'center'}}>

                                            <div style={{ flexDirection: 'row', display:'flex',justifyContent:'center', alignContent:'center', justifyItems:'center', alignItems:'flex-end', backgroundColor:'transparent'}}>
                                                <label style={{fontSize:'1.5vw', fontWeight:'bold', color:'#454545'}}>No Workout Available For This Activity</label>
                                            </div>
                                            </div>
                                            }
                                            <div style={{display:"flex", width:'100%', backgroundColor:`${colorPalette.default}33`, justifyContent:'flex-end'}}>
                                                {selectedDay.activities[selectedActivityIndex].workout ? 
                                                <></>
                                                :
                                                <div>
                                                    
                                                </div>
                                                }
                                            </div>

                                        </div>
                                        }
                                        <div style={{display:'flex',flex:3,backgroundColor:`${colorPalette.default}44`, flexDirection:'column',justifyContent:'flex-start', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'auto', backgroundColor:`${colorPalette.default}33`, flexDirection:'row', display:"flex", justifyContent:'flex-start', alignItems:'flex-start'}}>

                                            <div style={{width:'100%', height:'auto', backgroundColor:'transparent', flexDirection:'row', display:"flex", justifyContent:'flex-start', alignItems:'flex-start'}}>
                                                    <div className="section-buttons" onClick={()=>setSelectedWorkoutPart(0)} 
                                                    style={{
                                                        backgroundColor:selectedWorkoutPart===0?`${colorPalette.steelBlue}ff`:`${colorPalette.steelBlue}aa`,
                                                        borderStyle:'solid',
                                                        borderWidth:'0px',
                                                        borderBottomWidth:selectedWorkoutPart===0?'2px':'0px',
                                                        borderColor:'white',
                                                        width:'auto'
                                                        }}>
                                                        <label style={{
                                                            padding:'5px 20px', 
                                                            fontSize:'1vw', 
                                                            pointerEvents:'none',
                                                            }}>Details</label>
                                                    </div>
                                                    <div className="section-buttons" onClick={()=>setSelectedWorkoutPart(1)} 
                                                    style={{
                                                        backgroundColor:selectedWorkoutPart===1?`${colorPalette.steelBlue}ff`:`${colorPalette.steelBlue}aa`,
                                                        borderStyle:'solid',
                                                        borderWidth:'0px',
                                                        borderBottomWidth:selectedWorkoutPart===1?'2px':'0px',
                                                        borderColor:'white',
                                                        width:'auto'
                                                        }}>
                                                        <label 
                                                            style={{
                                                                padding:'5px 20px', 
                                                                fontSize:'1vw', 
                                                                pointerEvents:'none'
                                                                }}>Changes</label>
                                                    </div>
                                                    {/* <div className="section-buttons" onClick={()=>setSelectedWorkoutPart(2)} 
                                                    style={{
                                                        backgroundColor:selectedWorkoutPart===2?`${colorPalette.steelBlue}ff`:`${colorPalette.steelBlue}aa`,
                                                        borderStyle:'solid',
                                                        borderWidth:'0px',
                                                        borderBottomWidth:selectedWorkoutPart===2?'2px':'0px',
                                                        borderColor:'white',
                                                        width:'auto'
                                                        }}>
                                                        <label 
                                                            style={{
                                                                padding:'5px 20px', 
                                                                fontSize:'1vw', 
                                                                pointerEvents:'none'
                                                                }}>Files</label>
                                                    </div> */}
                                                </div>

                                            </div>
                                        {selectedWorkoutPart === 0 &&
                                        (
                                            <div style={{height:'100%', width:'100%', display:'flex',justifyContent:'flex-start', alignItems:'center', flexDirection:'column'}}>
                                                <div style={{display:'flex', width:'95%', marginTop:'8px',backgroundColor:`${colorPalette.steelBlue}`, justifyContent:'center', alignItems:'center'}}>
                                                    <label style={{padding:'8px', fontWeight:'bold',fontSize:'1vw', textAlign:'justify', overflowY:'scroll',}}>{`Training Stress Score: ${getSumTSS(selectedDay.activities[selectedActivityIndex], user)}`}</label>
                                                </div>
                                                <div style={{display:'flex', width:'95%', backgroundColor:`${colorPalette.steelBlue}aa`, justifyContent:'center', alignItems:'center'}}>
                                                    <label style={{padding:'8px', fontWeight:'bold',fontSize:'1vw',textAlign:'justify', overflowY:'scroll',}}>{`${getTotalActivityLength(selectedDay.activities[selectedActivityIndex])}`}</label>
                                                </div>

                                                <div style={{display:'flex', width:'95%', backgroundColor:`${colorPalette.steelBlue}`, justifyContent:'center', alignItems:'center'}}>
                                                </div>
                                            </div>
                                        )
                                        }
                                        {selectedWorkoutPart === 1 &&
                                        (
                                            <div style={{display:'flex', width:'100%', height:'100%', flex:1, backgroundColor:'#f1f1f1', flexDirection:'column', alignItems:'center'}}>

                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:2}}>
                                                    <label style={{fontSize:'1vw', color:`#454545`, backgroundColor:'#e1e1e1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Average Effort Offset`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1vw', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{user.reviews.length > 0 ? `${Math.round(aiChanges.avgEffort * 10)/10}` : `No Data Yet`}</label>
                                                </div>
                                            </div>
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:1}}>
                                                    <label style={{fontSize:'1vw', color:`#454545`, backgroundColor:'#e1e1e1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Adjustment`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1vw', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{user.reviews.length > 0 ? `${Math.round(aiChanges.adjustedTSSValue * 100)}%` : `No Data Yet`}</label>
                                                </div>
                                            </div>
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:1}}>
                                                    <label style={{fontSize:'1vw', color:`#454545`, backgroundColor:'#e1e1e1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`TSS`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:3, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1vw', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{`Updated from ${Math.round((getSumTSS(selectedDay.activities[selectedActivityIndex], user) - (aiChanges.adjustedTSSValue * getSumTSS(selectedDay.activities[selectedActivityIndex], user)))*10)/10} to ${getSumTSS(selectedDay.activities[selectedActivityIndex], user)}`}</label>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                        }
                                        {selectedWorkoutPart === 2 &&
                                        (
                                            selectedDay.activities[selectedActivityIndex].workout ?
                                            <div style={{height:'100%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                <FontAwesomeIcon onClick={()=>{}} style={{width:'2vw', height:'2vw', padding:'5px', marginBottom:'10px'}}  icon={faDownload}></FontAwesomeIcon>
                                                <label className="download-label" style={{fontWeight:'bold'}}>Download workout .fit file</label>
                                            </div>
                                            :
                                            <div style={{height:'100%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                <label className="download-label" style={{fontWeight:'bold'}}>No Files Available</label>
                                            </div>
                                        )
                                        }


                                        </div>

                                    </div>
                                </div>
                                )
                                :
                                (
                                <div className='card-enlarged'
                                    style={{
                                        backgroundColor: '#ffffff55', 
                                        height: '75vh', 
                                        width: '100%', 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        justifyContent: 'center', 
                                        alignItems: 'center'
                                    }}>
                                        {reviewStage === 0 && 
                                        <FontAwesomeIcon className="back-button" style={{position:'absolute', top:'20px', left:'20px', color:'#454545'}}  onClick={()=>setReviewingActivity(null)} icon={faArrowLeft}></FontAwesomeIcon>
                                        }
                                        {/* <h2 style={{fontSize:'40px'}} className="card-title" >Session Review</h2> */}
                                        <div className="session-review-form" >
                                            {reviewStage === 0 && (
                                                <div className="form-stage">
                                                    <h2 style={{ fontSize: '2.5vw', fontWeight: 'bold', color:'#454545'}}>Well Done!</h2>
                                                    <h1 style={{color:'#454545', fontSize:'2vw'}} className="single-line-text">Let's review your session and find out how it went</h1>
            
                                                    <div style={{width:'100%', flexDirection:'column',display:'flex', justifyContent:'center', alignItems:'center', borderRadius:'5px',}}>
                                                        <Button className="review-button" onClick={()=>{setReviewType('manual');nextStage();}}>REVIEW WITH <span style={{ color: 'steelblue', fontStyle: 'italic', marginLeft: '5px' }}>VITOVA</span></Button>
                                                        <button
                                                        onClick={()=>{setReviewType('strava');nextStage();}}
                                                        className="strava-button"
                                                        >
                                                            AUTO REVIEW WITH <span style={{ color: '#fc4c02', fontStyle: 'italic', marginLeft: '5px' }}>STRAVA</span>
                                                        </button> 
                                                        <Button className="review-button" onClick={()=>handleSubmitClick(true)}>I Had A Rest Day</Button>

                                                        </div>

                                                </div>
            
                                            )}
                                            {reviewStage === 1 && reviewType === 'manual' && reviewingActivity.type === 'Run' && (
                                                <div className="form-stage">
                                                    <h2>{`How Many Minutes Was Your ${reviewingActivity.type}?`}</h2>
                                                    <Slider 
                                                        defaultValue={reviewingActivity.duration}
                                                        onChangeCommitted={nextStage}
                                                        value={duration}
                                                        onChange={(e: Event, value: number | number[]) => setDuration(value as number)}
                                                        step={5} 
                                                        min={0} 
                                                        max={120}
                                                        marks
                                                        valueLabelDisplay="auto"
                                                        sx={sliderStyling}
                                                    />
                                                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                                    </div>
                                                </div>
                                            )}
                                            {reviewStage === 1 && reviewType === 'manual' && reviewingActivity.type === 'Cycle' && (
                                                <div className="form-stage">
                                                    <h2>{`How Many Kilometers Was Your ${reviewingActivity.type}?`}</h2>
                                                    <Slider 
                                                        defaultValue={reviewingActivity.duration}
                                                        onChangeCommitted={nextStage}
                                                        value={distance}
                                                        onChange={(e: Event, value: number | number[]) => setDistance(value as number)}
                                                        step={5} 
                                                        min={5} 
                                                        max={300}
                                                        marks
                                                        valueLabelDisplay="auto"
                                                        sx={sliderStyling}
                                                    />
                                                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                                    </div>
                                                </div>
                                            )}
                                            
                                            {reviewStage === 2 && reviewType === 'manual' && reviewingActivity.type === 'Run' && (
                                                <div className="form-stage">

                                                    <h2>What Was Your Average Pace?</h2>
                                                    <Slider
                                                        onChangeCommitted={nextStage}
                                                        value={pace}
                                                        onChange={handlePaceChange}
                                                        step={5}
                                                        min={60}
                                                        max={540}
                                                        marks={marks}
                                                        valueLabelDisplay="auto"
                                                        valueLabelFormat={paceStringToSeconds}
                                                        sx={sliderStyling}
                                                    />
                                                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                                    </div>
                                                </div>
                                            )}
                                            {reviewStage === 2 && reviewType === 'manual' && reviewingActivity.type === 'Cycle' && (
                                                <div className="form-stage">

                                                    <h2>What Was Your Average Power? (Watts)</h2>
                                                    <Slider
                                                        onChangeCommitted={nextStage}
                                                        value={power}
                                                        onChange={handlePowerChange}
                                                        step={5}
                                                        min={60}
                                                        max={500}
                                                        marks={marks}
                                                        valueLabelDisplay="auto"
                                                        sx={sliderStyling}
                                                    />
                                                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                                    </div>
                                                </div>
                                            )}
                                            
                                            {reviewStage === 3 && reviewType === 'manual' && (
                                                <div className="form-stage">
                                                    <h2>What Was Your Average Heart Rate?</h2>
                                                    <Slider 
                                                        onChangeCommitted={nextStage}
                                                        value={heartRate}
                                                        onChange={(e: Event, value: number | number[]) => setHeartRate(value as number)}
                                                        step={1} 
                                                        min={80} 
                                                        max={220}
                                                        marks
                                                        valueLabelDisplay="auto"
                                                        sx={sliderStyling}
                                                    />
                                                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                                    </div>
                                                </div>
                                            )}
                                            
                                            {reviewStage === 4 && reviewType === 'manual' && (
                                                <div className="form-stage">
                                                    <h2 style={{color:'#454545', fontWeight:'bold'}}>How Hard Did The Session Feel?</h2>
            
                                                    <Slider 
                                                        onChangeCommitted={nextStage}
                                                        value={effort}
                                                        onChange={(e: Event, value: number | number[]) => setEffort(value as number)}
                                                        step={1} 
                                                        min={0} 
                                                        max={10}
                                                        marks
                                                        valueLabelDisplay="auto"
                                                        sx={sliderStyling}
                                                    />
            
            
                                                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                                    </div>
                                                </div>
                                            )}
                                            {reviewStage === 1 && reviewType === 'strava' && (
                                                <div className="form-stage">
                                                    <h2>Select The Activities You Completed Today</h2>    

                                                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                                    </div>
                                                </div>
                                            )}
                                        
                                            {reviewStage === 5 && reviewType === 'manual' && (
                                                <div className="form-stage">
                                                    <h2 style={{color:'black'}}>Review Your Answers Before Submitting</h2>
                                                    <table style={{width: '60vw', borderCollapse: 'collapse'}}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{fontSize:'20px', padding:'10px', color:'#454545',borderBottom: '2px solid #000'}}>Duration</th>
                                                                <th style={{fontSize:'20px', padding:'10px', color:'#454545',borderBottom: '2px solid #000'}}>Average Pace</th>
                                                                <th style={{fontSize:'20px', padding:'10px', color:'#454545',borderBottom: '2px solid #000'}}>Average Heart Rate</th>
                                                                <th style={{fontSize:'20px', padding:'10px', color:'#454545',borderBottom: '2px solid #000'}}>Percieved Effort</th>
                                                                <th style={{fontSize:'20px', padding:'10px', color:'#454545',borderBottom: '2px solid #000'}}>Sleep Quality</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr key={1}>
                                                                <td style={{fontSize:'18px', padding:'10px', color:'#454545',borderBottom: '1px solid #ddd'}}>{duration}</td>
                                                                <td style={{fontSize:'18px', padding:'10px', color:'#454545',borderBottom: '1px solid #ddd'}}>{paceStringToSeconds(pace)}</td>
                                                                <td style={{fontSize:'18px', padding:'10px', color:'#454545',borderBottom: '1px solid #ddd'}}>{heartRate}</td>
                                                                <td style={{fontSize:'18px', padding:'10px', color:'#454545',borderBottom: '1px solid #ddd'}}>{effort} / 10</td>
                                                                <td style={{fontSize:'18px', padding:'10px', color:'#454545',borderBottom: '1px solid #ddd'}}>{sleepQuality} / 10</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                                        <Button className="form-next-button" onClick={()=>handleSubmitClick()}>Submit Review</Button>
                                                    </div>
                                                </div>
                                            )}
                                            {reviewStage === 6 && (
                                                <div className="form-stage">
                                                    <h2 style={{ fontSize: '2.75vw', fontWeight: 'bold' }}>Submitted!</h2>
                                                    <h1 className="single-line-text" style={{fontSize:'1.75vw'}}>We'll Update Your Plan Accordingly!</h1>
                                                    {/* <p>{`Your TTS based on FTHR was ${getTTSFromFTHR()}`}</p> */}
                                                    {/* <p>{`Your optimal TTS was ${getTTSFromActivity(reviewingActivity)}`}</p> */}
                                                    <div>
            
                                                    <button className="form-next-button" onClick={finishReview}>Back To Dashboard</button>
                                                    </div>
                                                </div>
            
                                            )}
                                        </div>
                                </div>
                                )
                        ) : (
                            // Display main view with activities list
                            <ul className="grid-container">
                                <li key={1} className="grid-item" >
                                    <div onClick={()=>setSelectedDay({day:'Overview', activities:[]})} className='card' style={{background:'linear-gradient(145deg, #2f3b4a, #1a2026)', borderRadius:'0'}} >
                                        <FontAwesomeIcon className="card-img" style={{color:'#ffffff', borderRadius:'0'}} icon={faBarsProgress} />
                                        <h2 className="card-title" style={{color:'#ffffff'}}>Overview</h2>
                                    </div>
                                </li>
            
                                {userPlan.content && next7Days.map((day, index) => {
                                    const week = getWeekNumber(day);
                                    return (
                                    <li key={index} className="grid-item">
                                        <div className='card' onClick={() => handleDayClick(day)} style={{borderRadius:'0'}}>
                                            {!isDayReviewed(day) && <FontAwesomeIcon style={{position:'absolute', right:10, top:10, color:'#943737', width:'30px', height:'30px'}} icon={faExclamationCircle}></FontAwesomeIcon>}
                                            <FontAwesomeIcon className="card-img" style={{color: isDayComplete(day) ? `#2f3b4a` : '#777777',borderRadius:'0'}} icon={isDayComplete(day) ? faCalendarCheck : faCalendar} />
                                            <h2 className="card-title" style={{color: isDayComplete(day) ? `#2f3b4a`: '#777777'}}>{getWeekDayName(user.userPlanStartDate.toString(), week, userPlan.content[week].days.indexOf(day))}</h2>
                                            <h1 style={{color: isDayComplete(day) ? `#2f3b4a` : '#777777', fontSize:'1vw'}}>{getCalendarDay(user.userPlanStartDate.toString(), week, userPlan.content[week].days.indexOf(day))}</h1>
                                        </div>
                                    </li>
                                    )})}
                            </ul>
                        )}
                    </div>
                    {/* RIGHT PANEL */}
                    <div className={`calendar-container ${showRightPanel ? 'show' : ''}`}>
                        {selectedTab === 'tests' ?
                            <ul className="grid-container">
                                <li key={1} className="grid-item" >
                                    <div onClick={()=>setSelectedDay({day:'Overview', activities:[]})} className='card' style={{background:'linear-gradient(145deg, #2f3b4a, #1a2026)', borderRadius:'0'}} >
                                        <FontAwesomeIcon className="card-img" style={{color:'#ffffff', borderRadius:'0'}} icon={faBarsProgress} />
                                        <h2 className="card-title" style={{color:'#ffffff'}}>Wellbeing</h2>
                                    </div>
                                </li>
                            </ul>
                            :
                        selectedTab === 'checkin' ?
                            <ul className="grid-container">
                                <li key={1} className="grid-item" >
                                    <div onClick={()=>setSelectedDay({day:'Overview', activities:[]})} className='card' style={{background:'linear-gradient(145deg, #2f3b4a, #1a2026)', borderRadius:'0'}} >
                                        <FontAwesomeIcon className="card-img" style={{color:'#ffffff', borderRadius:'0'}} icon={faBarsProgress} />
                                        <h2 className="card-title" style={{color:'#ffffff'}}>Check In</h2>
                                    </div>
                                </li>
                            </ul>
                        :
                            <div></div>
                        }

                    </div>
            </div>
        );
        }else{
            return(
                <div className="dashboard-page">
                    <div className="main-content">
                        <Spinner text="Adapting Your Plan!"/>
                    </div>
                </div>
            )

        }
    }else{
        return (
            <div className="dashboard-page">
                <div className="main-content">
                    <h1 style={{fontSize:'1.5em', marginBottom:'20px', fontWeight:'bold', color:'#454545'}}>You haven't set a plan yet</h1>
                    <Link to={'/plans'} className="review-button" style={{fontSize:'1.5vw', width:'auto'}}>Go To Plans</Link>
                </div>
            </div>
        )
    }

}

export default DashboardPageLoggedInView;