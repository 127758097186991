import { ConflictError, UnauthorizedError } from "../errors/http_errors";
import { ActivityStep, Plan } from "../models/plan";
import { Exercise } from "../models/exercise";
import { User } from "../models/user";
import { DBMedication } from "../models/medication";
import { DBCondition } from "../models/condition";

async function fetchData(input: RequestInfo, init?: RequestInit) {
    const response = await fetch(input, init);
    if (response.ok) {
        return response;
    } else {
        const errorBody = await response.json();
        const errorMessage = errorBody.error;
        if (response.status === 401) {
            throw new UnauthorizedError(errorMessage);
        } else if (response.status === 409) {
            throw new ConflictError(errorMessage);
        } else {
            throw Error("Request failed with status: " + response.status + " message: " + errorMessage);
        }
    }
}

export async function getLoggedInUser(): Promise<User> {
    const response = await fetchData("/api/users", { method: "GET" });
    return response.json();
}

export interface GetPatientsBody {
    gpReferralCode:string,
}

export async function getPatients(getPatientsBody: GetPatientsBody): Promise<Array<User>> {
    const response = await fetchData("/api/users/getReferredPatients", {
        method: "POST", // Use POST for sending the body
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(getPatientsBody), // Send the request body as JSON
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    return response.json();
}


export interface SignUpCredentials {
    fullname:string,
    email: string,
    password: string,
    accountType:string,
    GPAccount:boolean,
}

export async function signUp(credentials: SignUpCredentials): Promise<User> {
    const response = await fetchData("/api/users/signup",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        });
    return response.json();
}

export interface SubscribeCredentials {
    email: string,
}

export async function subscribe(credentials: SubscribeCredentials): Promise<User> {
    const response = await fetchData("/api/users/subscribe",
        {
            method:"POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        });
    return response.json();
}

export interface LoginCredentials {
    username: string,
    password: string,
}

export async function login(credentials: LoginCredentials): Promise<User> {
    const response = await fetchData("/api/users/login",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        });
    return response.json();
}

export interface AuthorizeStravaCredentials {
    code:string
}

export interface AuthorizationStravaResponse{
    access_token:string
}

export async function authorizeStrava(body: AuthorizeStravaCredentials): Promise<AuthorizationStravaResponse> {
    const response = await fetchData("/api/users/authorizestrava",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to authorize with Strava: ${errorData.error || response.statusText}`);
    }
    return response.json();
}


export async function fetchStravaActivities(access_token: string): Promise<any> {
    const response = await fetchData("/api/users/fetchstravaactivities", {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${access_token}`,
            "Content-Type": "application/json",
        },
    });
    return response.json();
}


export async function logout() {
    await fetchData("/api/users/logout", { method: "POST" });
}

export interface Image {
    imageURL:string,
}

export async function uploadProfileImage(input: string, key:string, imageType:string): Promise<Image> {
    const body = {
        data:input,
        key:key,
        imageType:imageType,
    }
    const response = await fetchData("/api/interviews/upload",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
    return response.json();
}

// interface Effort{
//     id:number,
//     type:string,
//     time:string,
//     avgHeartRate:number,
// }

export interface UserInput {
    user: User,
}


export async function updateUser(userId: string, user: User): Promise<User> {
    const response = await fetchData("/api/users/" + userId,
        {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
        });
    return response.json();
}

export async function createPlan(planData: Plan): Promise<Plan> {
    const response = await fetchData("/api/plans/upload",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(planData),
        });
    return response.json();
}
export interface getActivityStepData {
    description:string,
}


    export async function generateActivityStep(input: getActivityStepData): Promise<ActivityStep> {
        const response = await fetchData("/api/users/getActivityStep", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(input), // Wrap inputString in an object and stringify it
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    }

export async function getPlans(): Promise<Array<Plan>> {
    const response = await fetchData("/api/plans", { method: "GET" });
    return response.json();
}

export async function getExercises(): Promise<Array<Exercise>> {
    const response = await fetchData("/api/exercises", { method: "GET" });
    return response.json();
}

export async function createExercise(exerciseData: Exercise): Promise<Exercise> {
    const response = await fetchData("/api/exercises/upload",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(exerciseData),
        });
    return response.json();
}

export async function getMedications(): Promise<Array<DBMedication>> {
    const response = await fetchData("/api/medications", { method: "GET" });
    return response.json();
}

export async function getNHSMedications(): Promise<Array<object>> {
    const response = await fetchData("/api/medications/nhs", { method: "GET" });
    return response.json();
}


export async function createMedication(medicationData: DBMedication): Promise<DBMedication> {
    const response = await fetchData("/api/medications/upload",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(medicationData),
        });
    return response.json();
}

export async function getConditions(): Promise<Array<DBCondition>> {
    const response = await fetchData("/api/conditions", { method: "GET" });
    return response.json();
}

export async function createCondition(conditionData: DBCondition): Promise<DBCondition> {
    const response = await fetchData("/api/conditions/upload",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(conditionData),
        });
    return response.json();
}