import * as Api from "../network/api";
import {Medication, User } from '../models/user';
import '../styles/DatabasePage.css';
import { useEffect, useState } from "react";
import Spinner from "./Spinner";
// import exercisesList from '../localdata/exercises'; // Import the exercises list
// import medicationsList from '../localdata/products.json';

import BodyHighlighterDiagram from "./BodyHighlighter";
import { Button } from "react-bootstrap";
import { IExerciseData } from "react-body-highlighter";
import Select from 'react-select';
import colorPalette from "../utils/colors";
import { DBMedication } from "../models/medication";
import { DBCondition } from "../models/condition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell, faHeartPulse, faMinusCircle, faPills, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

interface DatabasePageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
    onPlanUpdated: (user: User) => void
}

interface Requirement {
    name:string,
    description:string,
    id:string,
}

interface Symptom {
    label:string,
    description:string,
    id:string,
}

interface Exercise {
    name: string;
    force: string;
    level: string;
    mechanic: string;
    equipment: string;
    primaryMuscles: string[];
    secondaryMuscles: string[];
    instructions: string[];
    category: string;
    images: string[];
    conditionsBlackList: string[];
    physicalRequirements:Requirement[];
    symptomBlackList:Symptom[];
    id: string;
}

const DatabasePageLoggedInView = ({ user, onLogoutSuccessful, onPlanUpdated }: DatabasePageLoggedInProps) => {
    const [loading, setLoading] = useState(true);
    const [filteredExercises, setFilteredExercises] = useState<Exercise[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [forceFilter, setForceFilter] = useState<string>("");
    const [levelFilter, setLevelFilter] = useState<string>("");
    const [musclesFilter, setMusclesFilter] = useState<string>("");
    const [conditionFilter, setConditionFilter] = useState<string>("");

    const [filteredMedications, setFilteredMedications] = useState<DBMedication[]>([]);
    const [filteredConditions, setFilteredConditions] = useState<DBCondition[]>([]);

    const [exercises, setExercises] = useState<Exercise[]>([]);
    const [medications, setMedications] = useState<DBMedication[]>([]);
    const [conditions, setConditions] = useState<DBCondition[]>([]);

    const [showPopup, setShowPopup] = useState(false);

    const [selectedExerciseSymptom, setSelectedExerciseSymptom] = useState<Symptom>({id:'', label:'', description:''});
    const [selectedSymptom, setSelectedSymptom] = useState<Symptom>({id:'', label:'', description:''});
    const [selectedMedication, setSelectedMedication] = useState<DBMedication>({medId:'',name:'',_id:''});

    const [selectedTab, setSelectedTab] = useState('exercises');

    const [isEditing, setIsEditing] = useState(false); // Flag to check if editing
    const [editIndex, setEditIndex] = useState<number | null>(null); // Store index of the exercise being edited
    const [currentStep, setCurrentStep] = useState(0); // Track current step in multi-step process
    const [newExercise, setNewExercise] = useState<Exercise>({
        name: '',
        force: '',
        level: '',
        mechanic: '',
        equipment: '',
        primaryMuscles: [],
        secondaryMuscles: [],
        instructions: [],
        category: '',
        images: [],
        conditionsBlackList: [],
        physicalRequirements:[],
        symptomBlackList:[],
        id: Math.random().toString(36).substr(2, 9)
    });
    const [newMedication, setNewMedication] = useState<DBMedication>({
        name:'',
        medId:''
    });
    const [newCondition, setNewCondition] = useState<DBCondition>({
        id:'',
        name:'',
        description:'',
        symptoms:[],
        commonMedications:[],
        cardioConstraints:{
            rpeMin:1,
            rpeMax:5,
            durationMin:30,
            durationMax:60,
            weeklyFreqMax:3,
            weeklyFreqMin:2
        },
        resistanceConstraints:{
            rpeMin:1,
            rpeMax:5,
            durationMin:30,
            durationMax:60,
            weeklyFreqMax:3,
            weeklyFreqMin:2,
            setsMin:1,
            setsMax:3,
            repsMin:10,
            repsMax:15,
            weeklyExercisesMin:5,
            weeklyExercisesMax:12,
        },
        flexibilityConstraints:{
            rpeMin:1,
            rpeMax:5,
            durationMin:30,
            durationMax:60,
            weeklyFreqMax:3,
            weeklyFreqMin:2
        }
    });

    const [selectedMuscles, setSelectedMuscles] = useState<IExerciseData[]>([{muscles:['abductors'], name:''}]);

    const requirementOptions = [
        { id: '0', label: 'Be Flexible' },
        { id: '1', label: 'Endure Long Cardiovascular Activity' },
        { id: '2', label: 'Reach Zone 4' },
        { id: '3', label: 'Maintain Balance' },
        { id: '4', label: 'Use Their Bodyweight' },
    ];

    const symptomOptions = [
        // Obesity-related symptoms
        { id: 's_0', label: 'Shortness of Breath', description: 'Difficulty breathing during physical activity, limiting endurance and aerobic capacity.' },
        { id: 's_1', label: 'Joint Pain', description: 'Pain in knees, hips, or lower back due to excess weight, making weight-bearing exercises difficult.' },
        { id: 's_2', label: 'Limited Range of Motion', description: 'Restricted movement in joints like the hips, knees, or shoulders, affecting flexibility exercises.' },
        { id: 's_3', label: 'Fatigue', description: 'General lack of energy, reducing stamina and ability to sustain workouts.' },
        { id: 's_4', label: 'Difficulty with Balance', description: 'Reduced stability during exercises requiring coordination, especially balance or agility exercises.' },
        
        // Diabetes-related symptoms
        { id: 's_5', label: 'Numbness or Tingling in Feet', description: 'Neuropathy causing loss of sensation in feet, increasing the risk of injury during exercises involving standing or running.' },
        { id: 's_6', label: 'Vision Problems', description: 'Blurred vision or eye strain, which may affect exercises that require precise coordination or balance.' },
        { id: 's_7', label: 'Slow Healing Sores', description: 'Increased risk of foot injuries, requiring caution during weight-bearing or high-impact exercises.' },
        { id: 's_8', label: 'Muscle Weakness', description: 'Reduced strength in muscles, limiting performance in resistance training or exercises requiring power.' },
        
        // Hypertension-related symptoms
        { id: 's_9', label: 'Dizziness', description: 'Lightheadedness during physical activity, particularly with quick movements or changes in position, affecting aerobic or balance exercises.' },
        { id: 's_10', label: 'Shortness of Breath', description: 'Difficulty breathing during intense physical activities, limiting aerobic capacity.' },
        { id: 's_11', label: 'Chest Pain', description: 'Tightness or discomfort in the chest during strenuous exercises, limiting intensity or requiring low-impact alternatives.' },
        { id: 's_12', label: 'Fatigue', description: 'General tiredness, impacting stamina and endurance during longer or high-intensity workouts.' },
        
        // Hypercholesterolemia-related symptoms
        { id: 's_13', label: 'Chest Pain (Angina)', description: 'Discomfort in the chest during exertion, requiring caution with high-intensity or cardiovascular exercises.' },
        { id: 's_14', label: 'Shortness of Breath', description: 'Breathing difficulties during aerobic exercises or physical exertion.' },
        { id: 's_15', label: 'Numbness in Extremities', description: 'Loss of sensation in hands or feet, which can affect grip or balance during exercises.' },
        { id: 's_16', label: 'Fatigue', description: 'Low energy, limiting endurance and ability to sustain physical activity.' },
    ];

    const [isSaving, setIsSaving] = useState(false);

    const medicationOptions: DBMedication[] = medications.map(medication => ({
        ...medication, // Spread original properties to maintain the DBMedication structure
        label: medication.name, // Add label and value if needed by react-select
        value: medication.medId
    }));
      

    const hashStringToId = (name: string) => {
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            const char = name.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash |= 0; // Convert to 32-bit integer
        }
        return Math.abs(hash).toString(36).substr(0, 9); // Convert to base-36 and limit to 9 characters
    };

    useEffect(() => {
        getExercises();
        getMedications();
        getConditions();
        getNHSMedications();
        // // FOR ADDING EXERCISES TO DATBASE
        // for (const m of medicationsList){
        //     const medicationToAdd:DBMedication = {
        //         name: m.DrugName,
        //         medId: m.ProductNo,
        //     }
        //     uploadMedication(medicationToAdd);
        // }
        // alert('done')

        // Simulate data loading
        setTimeout(() => {
            setLoading(false);
            setFilteredExercises(exercises as Exercise[]); // Type assertion to cast exercises as Exercise[]
            setFilteredMedications(medications as DBMedication[])
            setFilteredConditions(conditions as DBCondition[])
        }, 1000); // Simulating a network request delay
    }, []);

    async function uploadExercise(exerciseToAdd:Exercise){
        await Api.createExercise(exerciseToAdd);
    }

    async function uploadMedication(medicationToAdd:DBMedication){
        await Api.createMedication(medicationToAdd);
    }

    async function uploadCondition(conditionToAdd:DBCondition){
        await Api.createCondition(conditionToAdd);
    }

    async function getExercises(){
        const response = await Api.getExercises().then(res=>{
            setExercises(res);
            setFilteredExercises(res)
            console.log(res)
        });
        
    }

    async function getMedications(){
        const response = await Api.getMedications().then(res=>{
            setMedications(res);
            setFilteredMedications(res);
            console.log(res)
        });
    }

    async function getNHSMedications(){
        const response = await Api.getNHSMedications().then(res=>{
            console.log(res)
        });
    }

    async function getConditions(){
        const response = await Api.getConditions().then(res=>{
            setConditions(res);
            setFilteredConditions(res);
            console.log(res)
        });
    }

    const handleSearch = (value: string = searchTerm) => {
        // Use 'value' directly instead of 'searchTerm' since setSearchTerm is async
        setSearchTerm(value);
    
        if (selectedTab === 'exercises') {
            const filtered = exercises.length > 0 ? exercises.filter((exercise: Exercise) =>
                exercise.name.toLowerCase().trim().includes(value.toLowerCase().trim()) &&  // Use 'value' instead of 'searchTerm'
                (forceFilter ? exercise.force === forceFilter : true) &&
                (levelFilter ? exercise.level === levelFilter : true) &&
                (musclesFilter ? (exercise.primaryMuscles as string[]).includes(musclesFilter) : true) &&
                (conditionFilter ? (exercise.conditionsBlackList as string[]).includes(conditionFilter) : true)
            ) : [];
    
            setFilteredExercises(filtered);
        } else if (selectedTab === 'medications') {
            const filtered = medications.length > 0 ? medications.filter((medication: DBMedication) => 
                medication.name.toLowerCase().trim().includes(value.toLowerCase().trim())
            ) : [];
    
            setFilteredMedications(filtered);
        } else if (selectedTab === 'conditions') {
            const filtered = conditions.length > 0 ? conditions.filter((condition: DBCondition) => 
                condition.name.toLowerCase().trim().includes(value.toLowerCase().trim())
            ) : [];
    
            setFilteredConditions(filtered);
        }
    };
    

    const handleExerciseSymptomChange = (selectedOption:any) => {
        setSelectedExerciseSymptom(selectedOption ? {
            description:selectedOption.label,
            label:'',
            id:''
        } : {
            description:'',
            label:'',
            id:''
        });
    };

    const handleSymptomChange = (selectedOption: any) => {
    setSelectedSymptom(selectedOption); // selectedOption will be an object like { value: 'dizziness', label: 'Dizziness' }
    };

    const handleMedicationOptionChange = (selectedOption: any) => {
        setSelectedMedication(selectedOption); // selectedOption will be an object like { value: 'dizziness', label: 'Dizziness' }
        };

    const removeExerciseSymptom = (symptom:Symptom) =>{
        // Create a new array that filters out the requirement to be removed
        const updatedExerciseSymptoms = newExercise.symptomBlackList.filter(
            (sym) => sym !== symptom
        );

        // Update the state with the new array of physical requirements
        setNewExercise({
            ...newExercise, // Spread the existing newExercise object
            symptomBlackList: updatedExerciseSymptoms, // Update the physicalRequirements array
        });
    }

    const removeConditionSymptom = (symptom:Symptom) =>{
        // Create a new array that filters out the requirement to be removed
        const updatedSymptoms = newCondition.symptoms.filter(
            (s) => s !== symptom
        );

        // Update the state with the new array of physical requirements
        setNewCondition({
            ...newCondition, // Spread the existing newExercise object
            symptoms: updatedSymptoms, // Update the physicalRequirements array
        });
    }

    const removeConditionMedication = (medication:DBMedication) =>{
        // Create a new array that filters out the requirement to be removed
        const updatedMedications = newCondition.commonMedications.filter(
            (m) => m !== medication
        );

        // Update the state with the new array of physical requirements
        setNewCondition({
            ...newCondition, // Spread the existing newExercise object
            commonMedications: updatedMedications, // Update the physicalRequirements array
        });
    }
    const showAddExercisePopup = () => {
        setIsEditing(false);  // Set to false when adding a new exercise
        setNewExercise({
            name: '',
            force: '',
            level: '',
            mechanic: '',
            equipment: '',
            primaryMuscles: [],
            secondaryMuscles: [],
            instructions: [],
            category: '',
            images: [],
            conditionsBlackList: [],
            physicalRequirements:[],
            symptomBlackList:[],
            id: Math.random().toString(36).substr(2, 9) // Generate new random ID
        });
        setCurrentStep(0); // Start from step 0
        setShowPopup(true);
    };

    const showAddMedicationPopup = () => {
        setIsEditing(false);  // Set to false when adding a new medication
        setNewMedication({
            name:'',
            medId:''
        });
        setCurrentStep(0); // Start from step 0
        setShowPopup(true);
    };

    const showAddConditionPopup = () => {
        setIsEditing(false);  // Set to false when adding a new condition
        setNewCondition({
        id:'',
        name:'',
        description:'',
        symptoms:[],
        commonMedications:[],
        cardioConstraints:{
            rpeMin:1,
            rpeMax:5,
            durationMin:30,
            durationMax:60,
            weeklyFreqMax:3,
            weeklyFreqMin:2
        },
        resistanceConstraints:{
            rpeMin:1,
            rpeMax:5,
            durationMin:30,
            durationMax:60,
            weeklyFreqMax:3,
            weeklyFreqMin:2,
            setsMin:1,
            setsMax:3,
            repsMin:10,
            repsMax:15,
            weeklyExercisesMin:5,
            weeklyExercisesMax:12,
        },
        flexibilityConstraints:{
            rpeMin:1,
            rpeMax:5,
            durationMin:30,
            durationMax:60,
            weeklyFreqMax:3,
            weeklyFreqMin:2
        }
    });
        setCurrentStep(0); // Start from step 0
        setShowPopup(true);
    };

    const editExercise = (index: number) => {
        const exerciseToEdit = filteredExercises[index];
        setNewExercise(exerciseToEdit);  // Populate the popup with the exercise details
        setIsEditing(true);              // Set the flag to true for editing mode
        setEditIndex(index);             // Store the index of the exercise being edited
        setCurrentStep(0);               // Start from step 0 when editing
        setShowPopup(true);              // Show the popup
    };

    const editMedication = (index: number) => {
        const medicationToEdit = filteredMedications[index];
        setNewMedication(medicationToEdit);  // Populate the popup with the exercise details
        setIsEditing(true);              // Set the flag to true for editing mode
        setEditIndex(index);             // Store the index of the exercise being edited
        setCurrentStep(0);               // Start from step 0 when editing
        setShowPopup(true);              // Show the popup
    };

    const editCondition = (index: number) => {
        const conditionToEdit = filteredConditions[index];
        setNewCondition(conditionToEdit);  // Populate the popup with the exercise details
        setIsEditing(true);              // Set the flag to true for editing mode
        setEditIndex(index);             // Store the index of the exercise being edited
        setCurrentStep(0);               // Start from step 0 when editing
        setShowPopup(true);              // Show the popup
    };

    const handleAddExerciseSymptom = () => {
        // Create a copy of the current newExercise object
        const updatedExercise = {
            ...newExercise,
            symptomBlackList: [...newExercise.symptomBlackList, selectedExerciseSymptom],
        };
    
        // Update the state with the new object
        setNewExercise(updatedExercise);
    };
    
    const handleAddSymptom = () => {
        // Create a copy of the current newExercise object
        const updatedCondition = {
            ...newCondition,
            symptoms: [...newCondition.symptoms, selectedSymptom],
        };
    
        // Update the state with the new object
        setNewCondition(updatedCondition);
        console.log(newCondition)

    };

    const handleAddMedication = () => {
        // Create a copy of the current newExercise object
        const updatedCondition = {
            ...newCondition,
            commonMedications: [...newCondition.commonMedications, selectedMedication],
        };
    
        // Update the state with the new object
        setNewCondition(updatedCondition);
        console.log(newCondition)

    };

    const closePopup = () => {
        setShowPopup(false);
        setIsEditing(false);
        setEditIndex(null);
    };

    const handleExerciseChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewExercise({ ...newExercise, [name]: value });
    };

    const handleMedicationChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewMedication({ ...newMedication, [name]: value });
    };

    const handleConditionChange = (event:any) => {
        const { name, value } = event.target;
    
        setNewCondition((prevCondition:any) => {
            if (name.includes('.')) {
                const [category, field] = name.split('.');
                return {
                    ...prevCondition,
                    [category]: {
                        ...prevCondition[category],
                        [field]: value,
                    },
                };
            } else {
                return {
                    ...prevCondition,
                    [name]: value,
                };
            }
        });
    };
    
    
    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handleBack = () => {
        if (currentStep > 0){
            setCurrentStep(currentStep - 1);

        }
    };

    const handleSaveExercise = async () => {
        setIsSaving(true);

        if (isEditing && editIndex !== null) {
            const updatedExercises = [...filteredExercises];
            updatedExercises[editIndex] = newExercise; // Replace the exercise at the current index
            setFilteredExercises(updatedExercises);
        } else {
            setFilteredExercises([newExercise, ...filteredExercises]);
        }
        await Api.createExercise(newExercise);
        closePopup();
        setIsSaving(false)
    };

    const handleSaveMedication = async () => {
        setIsSaving(true);

        if (isEditing && editIndex !== null) {
            const updatedMedications = [...filteredMedications];
            updatedMedications[editIndex] = newMedication; // Replace the exercise at the current index
            setFilteredMedications(updatedMedications);
        } else {
            setFilteredMedications([newMedication, ...filteredMedications]);
        }
        await Api.createMedication(newMedication);
        closePopup();
        setIsSaving(false)
    };

    const handleSaveCondition = async () => {
        setIsSaving(true);
        if (newCondition.name.length > 0){
            if (isEditing && editIndex !== null) {
                const updatedConditions = [...filteredConditions];
                updatedConditions[editIndex] = newCondition; // Replace the exercise at the current index
                setFilteredConditions(updatedConditions);
            } else {
                setFilteredConditions([newCondition, ...filteredConditions]);
            }
            await Api.createCondition(newCondition);
            closePopup();
        }else{
            alert('Save failed - condition must have a name')
        }
        setIsSaving(false);

    };

    const renderExerciseStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <label className="step-title">Step 1: Basic Information</label>
                        <label style={{marginTop:'20px'}}>Exercise Name</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="name"
                            placeholder="Exercise Name"
                            value={newExercise.name}
                            onChange={handleExerciseChange}
                        />
                        <label style={{marginTop:'20px'}}>Force</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="force"
                            placeholder="Force (e.g., Push/Pull)"
                            value={newExercise.force}
                            onChange={handleExerciseChange}
                        />
                    </>
                );
            case 1:
                return (
                    <>
                        <label className="step-title">Step 2: Equipment and Level</label>
                        <label style={{marginTop:'20px'}}>Difficulty Level</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="level"
                            placeholder="Level (e.g., Beginner/Intermediate)"
                            value={newExercise.level}
                            onChange={handleExerciseChange}
                        />
                        <label style={{marginTop:'20px'}}>Equipment Required</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="equipment"
                            placeholder="Equipment"
                            value={newExercise.equipment}
                            onChange={handleExerciseChange}
                        />
                    </>
                );
            case 2:
                return (
                    <>
                        <label className="step-title">Step 3: Muscles and Category</label>
                        <label style={{marginTop:'20px'}}>Category</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="category"
                            placeholder="Category"
                            value={newExercise.category}
                            onChange={handleExerciseChange}
                        />
                        <label style={{marginTop:'20px'}}>Muscle Groups Used</label>

                        <BodyHighlighterDiagram 
                            width={80} height={30} 
                            data={[]}
                            />

                    </>
                );
                case 3:
                    return (
                        <>
                            <label className="step-title">Step 4: Symptom Black List</label>
                            <label style={{marginTop:'20px'}}>{`A ${newExercise.name} is not suitable if the patient suffers from these symptoms ...`}</label>
                            <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                            <Select
                                className="exercise-info-input"
                                placeholder="Symptoms"
                                name="symptoms"
                                value={symptomOptions.find((option:any) => 
                                    newExercise.symptomBlackList.map((s) => s.id).includes(option.id)
                                )}                                
                                onChange={handleExerciseSymptomChange}
                                options={symptomOptions}
                                isClearable
                            />
                            <Button className="form-next-button" style={{marginTop:'20px', marginLeft:'5px', fontSize:'1vw'}} onClick={handleAddExerciseSymptom}>Add Symptom</Button>

                            </div>
                            <div className="symptom-list">
                            {newExercise.symptomBlackList.map((symptom: Symptom) => (
                                <div className="symptom-item" key={symptom.id} >
                                <FontAwesomeIcon onClick={() => removeExerciseSymptom(symptom)} icon={faMinusCircle} className="symptom-icon" /> {/* Icon next to symptom */}
                                <label className="symptom-description">{symptom.description}</label>
                                </div>
                            ))}
                            </div>

                            </>
                    );
            default:
                return null;
        }
    };

    const renderMedicationStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <label className="step-title">Step 1: Basic Information</label>
                        <label style={{marginTop:'20px'}}>Medication Name</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="name"
                            placeholder="Medication Name"
                            value={newMedication.name}
                            onChange={handleMedicationChange}
                        />
                        <label style={{marginTop:'20px'}}>Medication ID</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            disabled
                            name="medId"
                            placeholder="Medication ID"
                            value={newMedication.medId}
                            onChange={handleMedicationChange}
                        />
                    </>
                );
            default:
                return null;
        }
    };
    
    const renderConditionStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <label className="step-title">Step 1: Basic Information</label>
                        <label style={{marginTop:'20px'}}>Condition Name</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="name"
                            placeholder="Condition Name"
                            value={newCondition.name}
                            onChange={handleConditionChange}
                        />
                        <label style={{marginTop:'20px'}}>Condition ID</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            disabled
                            name="id"
                            placeholder="Condition ID"
                            value={newCondition.id}
                            onChange={handleConditionChange}
                        />
                         <label style={{marginTop:'20px'}}>Condition Description</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="description"
                            placeholder="Condition Description"
                            value={newCondition.description}
                            onChange={handleConditionChange}
                        />
                    </>
                );
            case 1:
                return (
                    <>
                        <label className="step-title">Step 2: Common Medications</label>
                        <label style={{ marginTop: '20px' }}>{`List all the common medications of ${newCondition.name}`}</label>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Select
                            className="exercise-info-input"
                            placeholder="Medications"
                            name="commonMedications"
                            value={medicationOptions.find(m=>m.name===selectedMedication.name)}
                            onChange={handleMedicationOptionChange}
                            options={medicationOptions}
                            // isClearable
                        />
                        <Button className="form-next-button" style={{ marginTop: '20px', marginLeft: '5px', fontSize: '1vw' }} onClick={handleAddMedication}>
                            Add Medication
                        </Button>
                        </div>

                        {/* Displaying selected symptoms */}
                        <div className="symptom-list">
                            {newCondition.commonMedications.map((medication: DBMedication) => (
                                <div className="symptom-item" key={medication.medId} >
                                <FontAwesomeIcon onClick={() => removeConditionMedication(medication)} icon={faMinusCircle} className="symptom-icon" /> {/* Icon next to symptom */}
                                <label className="symptom-description">{medication.name}</label>
                                </div>
                            ))}
                            </div>
                    </>

                );
            case 2:
                return (
                    <>
                        <label className="step-title">Step 3: Symptoms</label>
                        <label style={{ marginTop: '20px' }}>{`List all the symptoms of ${newCondition.name}`}</label>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Select
                            className="exercise-info-input"
                            placeholder="Symptoms"
                            name="symptoms"
                            value={symptomOptions.find(o=>o.id===selectedSymptom.id)}
                            onChange={handleSymptomChange}
                            options={symptomOptions}
                            // isClearable
                        />
                        <Button className="form-next-button" style={{ marginTop: '20px', marginLeft: '5px', fontSize: '1vw' }} onClick={handleAddSymptom}>
                            Add Symptom
                        </Button>
                        </div>

                        {/* Displaying selected symptoms */}
                        <div className="symptom-list">
                            {newCondition.symptoms.map((symptom: Symptom) => (
                                <div className="symptom-item" key={symptom.id} >
                                <FontAwesomeIcon onClick={() => removeConditionSymptom(symptom)} icon={faMinusCircle} className="symptom-icon" /> {/* Icon next to symptom */}
                                <label className="symptom-description">{symptom.label}</label>
                                </div>
                            ))}
                            </div>
                    </>

                );
            case 3:
                return (
                    <>
                        <label className="step-title">Step 4: Cardio Constraints</label>
                        <label style={{ marginTop: '20px' }}>{`Specify all the cardio constraints of ${newCondition.name}`}</label>

                        {/* RPE Min */}
                        <label style={{ marginTop: '10px' }}>RPE Min</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="cardioConstraints.rpeMin"
                            placeholder="RPE Min"
                            value={newCondition.cardioConstraints.rpeMin}
                            onChange={handleConditionChange}
                        />

                        {/* RPE Max */}
                        <label style={{ marginTop: '10px' }}>RPE Max</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="cardioConstraints.rpeMax"
                            placeholder="RPE Max"
                            value={newCondition.cardioConstraints.rpeMax}
                            onChange={handleConditionChange}
                        />

                        {/* Weekly Freq Min */}
                        <label style={{ marginTop: '10px' }}>Weekly Freq Min</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="cardioConstraints.weeklyFreqMin"
                            placeholder="Weekly Frequency Min"
                            value={newCondition.cardioConstraints.weeklyFreqMin}
                            onChange={handleConditionChange}
                        />

                        {/* Weekly Freq Max */}
                        <label style={{ marginTop: '10px' }}>Weekly Freq Max</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="cardioConstraints.weeklyFreqMax"
                            placeholder="Weekly Frequency Max"
                            value={newCondition.cardioConstraints.weeklyFreqMax}
                            onChange={handleConditionChange}
                        />

                        {/* Duration Min */}
                        <label style={{ marginTop: '10px' }}>Duration Min</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="cardioConstraints.durationMin"
                            placeholder="Duration Min"
                            value={newCondition.cardioConstraints.durationMin}
                            onChange={handleConditionChange}
                        />

                        {/* Duration Max */}
                        <label style={{ marginTop: '10px' }}>Duration Max</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="cardioConstraints.durationMax"
                            placeholder="Duration Max"
                            value={newCondition.cardioConstraints.durationMax}
                            onChange={handleConditionChange}
                        />
                    </>

                );
                case 4:
                return (
                    <>
                        <label className="step-title">Step 5: Resistance Constraints</label>
                        <label style={{ marginTop: '20px' }}>{`Specify all the resistance constraints of ${newCondition.name}`}</label>
            
                        {/* Resistance Constraints Inputs */}
                        <label style={{ marginTop: '10px' }}>RPE Min</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.rpeMin"
                            placeholder="RPE Min"
                            value={newCondition.resistanceConstraints.rpeMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>RPE Max</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.rpeMax"
                            placeholder="RPE Max"
                            value={newCondition.resistanceConstraints.rpeMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Weekly Frequency Min</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.weeklyFreqMin"
                            placeholder="Weekly Frequency Min"
                            value={newCondition.resistanceConstraints.weeklyFreqMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Weekly Frequency Max</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.weeklyFreqMax"
                            placeholder="Weekly Frequency Max"
                            value={newCondition.resistanceConstraints.weeklyFreqMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Duration Min</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.durationMin"
                            placeholder="Duration Min"
                            value={newCondition.resistanceConstraints.durationMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Duration Max</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.durationMax"
                            placeholder="Duration Max"
                            value={newCondition.resistanceConstraints.durationMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Sets Min</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.setsMin"
                            placeholder="Sets Min"
                            value={newCondition.resistanceConstraints.setsMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Sets Max</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.setsMax"
                            placeholder="Sets Max"
                            value={newCondition.resistanceConstraints.setsMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Reps Min</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.repsMin"
                            placeholder="Reps Min"
                            value={newCondition.resistanceConstraints.repsMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Reps Max</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.repsMax"
                            placeholder="Reps Max"
                            value={newCondition.resistanceConstraints.repsMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Weekly Exercises Min</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.weeklyExercisesMin"
                            placeholder="Weekly Exercises Min"
                            value={newCondition.resistanceConstraints.weeklyExercisesMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Weekly Exercises Max</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.weeklyExercisesMax"
                            placeholder="Weekly Exercises Max"
                            value={newCondition.resistanceConstraints.weeklyExercisesMax}
                            onChange={handleConditionChange}
                        />
                    </>
                );
            
            case 5:
                return (
                    <>
                        <label className="step-title">Step 6: Flexibility Constraints</label>
                        <label style={{ marginTop: '20px' }}>{`Specify all the flexibility constraints of ${newCondition.name}`}</label>
            
                        {/* Flexibility Constraints Inputs */}
                        <label style={{ marginTop: '10px' }}>RPE Min</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="flexibilityConstraints.rpeMin"
                            placeholder="RPE Min"
                            value={newCondition.flexibilityConstraints.rpeMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>RPE Max</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="flexibilityConstraints.rpeMax"
                            placeholder="RPE Max"
                            value={newCondition.flexibilityConstraints.rpeMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Weekly Frequency Min</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="flexibilityConstraints.weeklyFreqMin"
                            placeholder="Weekly Frequency Min"
                            value={newCondition.flexibilityConstraints.weeklyFreqMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Weekly Frequency Max</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="flexibilityConstraints.weeklyFreqMax"
                            placeholder="Weekly Frequency Max"
                            value={newCondition.flexibilityConstraints.weeklyFreqMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Duration Min</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="flexibilityConstraints.durationMin"
                            placeholder="Duration Min"
                            value={newCondition.flexibilityConstraints.durationMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Duration Max</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="flexibilityConstraints.durationMax"
                            placeholder="Duration Max"
                            value={newCondition.flexibilityConstraints.durationMax}
                            onChange={handleConditionChange}
                        />
                    </>
                );
            
            default:
                return null;
        }
    };
    
    return (
        <div className="database-page-container" style={{height:'100%'}}>
            {loading ? (
                <Spinner text="Loading Database" />
            ) : showPopup ? (
                    <div className="card-enlarged" style={{ display: 'flex', flexDirection: 'column', color:'#454545', justifyContent:'flex-start'}}>
                        <div style={{display:'flex', alignItems:'center',justifyContent:'flex-start', flexDirection:'column'}}>
                            {selectedTab === 'exercises' &&
                                renderExerciseStepContent()
                            }
                            {selectedTab === 'medications' &&
                                renderMedicationStepContent()  
                            }
                            {selectedTab === 'conditions' && 
                                <div style={{ 
                                    display: 'flex', 
                                    height: '60vh', 
                                    maxHeight: '60vh', 
                                    flexDirection: 'column', 
                                    alignItems:'center',
                                    overflowY: 'auto' // Allows vertical scrolling if content exceeds 30vh
                                }}>
                                    {renderConditionStepContent()}
                                </div>

                            }
                        </div>
                        <div style={{width:'90%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:'10vh'}}>
                            {currentStep <= 0 ? 
                                <Button className="form-back-button" onClick={closePopup}>Close Without Saving</Button>
                            :
                                <Button className="form-back-button" onClick={handleBack}>Previous</Button>
                            }
                            {(selectedTab === 'exercises' && currentStep < 3) || (selectedTab === 'medications' && currentStep < 1) || (selectedTab === 'conditions' && currentStep < 5) ? 
                                <Button className="form-next-button" onClick={handleNext}>Next Step</Button>
                            :
                                selectedTab === 'exercises'?

                                <Button className="form-next-button" disabled={isSaving} onClick={handleSaveExercise}>{isEditing ? "Update Exercise" : "Save Exercise"}</Button>
                                :
                                selectedTab === 'medications'?
                                <Button className="form-next-button" disabled={isSaving} onClick={handleSaveMedication}>{isEditing ? "Update Medication" : "Save Medication"}</Button>
                                :
                                <Button className="form-next-button" disabled={isSaving} onClick={handleSaveCondition}>{isEditing ? "Update Condition" : "Save Condition"}</Button>


                            }
                        </div>
                    </div>
            ) : (
                <div style={{height:'auto', minHeight:'100vh', display:'flex', flexDirection:'column'}}>
                    <h1 className="database-page-header" style={{fontWeight:'normal'}}><b>VITOVA</b> DATABASE</h1>

                    <div style={{display:'flex', width:'100%', height:'8vh', backgroundColor:'transparent', marginBottom:'2vh'}}>
                        <div className="tab" onClick={()=>setSelectedTab('exercises')} style={{backgroundColor:`${colorPalette.steelBlue}`}}>
                            <label className="tab-label" style={{color:'#f1f1f1', fontWeight:selectedTab === 'exercises'?'bold':'normal', fontSize:'1.25vw', fontStyle:'italic'}}>EXERCISES</label>
                            <FontAwesomeIcon style={{marginLeft:'10px'}} icon={faDumbbell} color="white"/>

                        </div>
                        <div className="tab" onClick={()=>setSelectedTab('medications')} style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', backgroundColor:`${colorPalette.steelBlue}ee`}}>
                            <label className="tab-label" style={{color:'#f1f1f1', fontWeight:selectedTab === 'medications'?'bold':'normal', fontSize:'1.25vw', fontStyle:'italic'}}>MEDICATIONS</label>
                            <FontAwesomeIcon style={{marginLeft:'10px'}} icon={faPills} color="white"/>

                        </div>
                        <div className="tab" onClick={()=>setSelectedTab('conditions')} style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', backgroundColor:`${colorPalette.steelBlue}`}}>
                            <label className="tab-label" style={{color:'#f1f1f1', fontWeight:selectedTab === 'conditions'?'bold':'normal', fontSize:'1.25vw', fontStyle:'italic'}}>CONDITIONS</label>
                            <FontAwesomeIcon style={{marginLeft:'10px'}} icon={faHeartPulse} color="white"/>


                        </div>

                    </div>
                    {selectedTab === 'exercises' &&
                        <div>
                        {/* Search Bar */}
                        <input
                            type="text"
                            placeholder="Search exercises..."
                            value={searchTerm}
                            className="database-page-search-bar"
                            onChange={(e) => handleSearch(e.target.value)}
                        />

                        {/* Filters */}
                        <div className="database-page-filters">
                            <select value={forceFilter} className="database-page-filter-select" onChange={(e) => setForceFilter(e.target.value)}>
                                <option value="">All Forces</option>
                                <option value="pull">Pull</option>
                                <option value="push">Push</option>
                            </select>

                            <select value={levelFilter} className="database-page-filter-select" onChange={(e) => setLevelFilter(e.target.value)}>
                                <option value="">All Levels</option>
                                <option value="beginner">Beginner</option>
                                <option value="intermediate">Intermediate</option>
                                <option value="advanced">Advanced</option>
                            </select>

                            <select value={musclesFilter} className="database-page-filter-select" onChange={(e) => setMusclesFilter(e.target.value)}>
                                <option value="">All Muscle Groups</option>
                                <option value="quadriceps">Quads</option>
                                <option value="biceps">Biceps</option>
                                <option value="lats">Lats</option>
                                <option value="hamstring">Hamstrings</option>
                                <option value="abs">Abdominals</option>
                            </select>

                            <select value={conditionFilter} className="database-page-filter-select" onChange={(e) => setConditionFilter(e.target.value)}>
                                <option value="">No Conditions</option>
                                <option value="obesity">Obesity</option>
                                <option value="anxiety">Anxiety</option>
                                <option value="depression">Depression</option>
                            </select>

                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                                <button className="database-page-filter-button" onClick={showAddExercisePopup}>Add Exercise</button>
                                <button className="database-page-filter-button" onClick={()=>handleSearch()}>Apply Filters</button>
                            </div>
                        </div>

                        {/* Exercise List */}
                        <ul className="database-page-exercise-list">
                            {filteredExercises.slice(0, 25).map((exercise, index) => (
                                // <li key={exercise.id} className="database-page-exercise-item" onClick={() => editExercise(index)}>
                                    <div className="exercise-card" onClick={() => editExercise(index)}>
                                        {/* Display the exercise image if available */}
                                        {exercise.images.length > 0 ?
                                            <div className="exercise-card-image">
                                                <img     
                                                    src={`https://raw.githubusercontent.com/yuhonas/free-exercise-db/main/exercises/${exercise.images[0]}`} 
                                                    alt={exercise.name} 
                                                    />
                                            </div>
                                            :                                            
                                            <div className="exercise-card-image">
                                            <img     
                                                src={`https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930`} 
                                                alt={exercise.name} 
                                                />
                                            </div>
                                        }
                                        <div className="exercise-card-content">
                                            <h3 style={{ fontWeight:'bold', fontStyle:'italic', fontSize:'1.5vw'}} className="exercise-card-title">{exercise.name.toUpperCase()}</h3>
                                            <p style={{ fontWeight:'bold', fontStyle:'italic'}}><strong>Force:</strong> {exercise.force.toUpperCase()}</p>
                                            <p style={{ fontWeight:'bold', fontStyle:'italic'}}><strong>Level:</strong> {exercise.level.toUpperCase()}</p>
                                            <p style={{ fontWeight:'bold', fontStyle:'italic'}}><strong>Equipment:</strong> {exercise.equipment.toUpperCase()}</p>
                                            <p style={{ fontWeight:'bold', fontStyle:'italic'}}><strong>Primary Muscles:</strong> {exercise.primaryMuscles.join(', ').toUpperCase()}</p>
                                            <p style={{ fontWeight:'bold', fontStyle:'italic'}}><strong>Category:</strong> {exercise.category.toUpperCase()}</p>
                                        </div>
                                    </div>
                                // </li>
                            ))}
                        </ul>

                    </div>
                    }
                    {selectedTab === 'medications' &&
                        <div>
                            <div style={{ display: 'flex', width: '100vw', alignItems: 'center' }}>
                            {/* Search Bar */}
                            <input
                                style={{ height: '6vh', width: '80%' }}
                                type="text"
                                placeholder="Search medications..."
                                value={searchTerm}
                                className="database-page-search-bar"
                                onChange={(e) => handleSearch(e.target.value)}
                            />

                            {/* Filters */}
                            <div className="database-page-filters" style={{ marginLeft: '10px', marginTop:'10px'}}>
                                <button
                                className="database-page-filter-button"
                                onClick={showAddMedicationPopup}
                                style={{ height: '6vh' }}
                                >
                                Add Medication
                                </button>
                            </div>
                            </div>

                        <label style={{marginLeft:'1vw', marginBottom:'1vw'}}>{`Number of results: ${filteredMedications.length}`}</label>

                        {/* MEdications List */}
                        <ul className="medication-list">
                            {filteredMedications.slice(0, 25).map((medication, index) => (
                                <li
                                key={medication._id || medication.medId}
                                className="medication-item"
                                onClick={() => editMedication(index)}
                                >
                                <div className="medication-card">
                                    <div className="medication-header">
                                    <h3 style={{ fontWeight:'bold', fontStyle:'italic'}}>{medication.name.toUpperCase()}</h3>
                                    </div>
                                </div>
                                </li>
                            ))}
                        </ul>

                    </div>
                    }
                    {selectedTab === 'conditions' &&
                    <div>
                        <div style={{ display: 'flex', width: '100vw', height:'100%',alignItems: 'center'}}>
                            {/* Search Bar */}
                            <input
                                style={{ height: '4vh', width: '83%', fontSize: '0.8em' }}
                                type="text"
                                placeholder="Search conditions..."
                                value={searchTerm}
                                className="database-page-search-bar"
                                onChange={(e) => handleSearch(e.target.value)}
                            />

                            {/* Filters */}
                            <div className="database-page-filters" style={{ marginLeft: '5px', marginTop: '5px' }}>
                                <button
                                    className="database-page-filter-button"
                                    onClick={showAddConditionPopup}
                                    style={{ height: '4vh', fontSize: '0.8em', padding: '4px 6px' }}
                                >
                                    Add Condition
                                </button>
                            </div>
                        </div>

                        <label style={{ marginLeft: '1vw', marginBottom: '1vw', fontSize: '0.8em' }}>
                            {`Number of results: ${filteredConditions.length}`}
                        </label>

                        {/* Conditions Table */}
                        <table className="conditions-table" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px', fontSize: '0.7em' }}>
                        <thead>
                            <tr>
                            {/* General Information */}
                            <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>Name</th>
                            <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>Description</th>
                            <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>Symptoms</th>
                            <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>Medications</th>

                            {/* Grouped Categories */}
                            <th colSpan={6} style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>Cardio Constraints</th>
                            <th colSpan={10} style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>Resistance Constraints</th>
                            <th colSpan={8} style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>Flexibility Constraints</th>
                            </tr>
                            <tr>
                            {/* Cardio Subheadings */}
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>RPE Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>RPE Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Freq Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Freq Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Dur Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Dur Max</th>

                            {/* Resistance Subheadings */}
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>RPE Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>RPE Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Freq Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Freq Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Dur Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Dur Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Sets Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Sets Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Reps Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Reps Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Weekly Ex Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Weekly Ex Max</th>

                            {/* Flexibility Subheadings */}
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>RPE Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>RPE Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Freq Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Freq Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Dur Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Dur Max</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredConditions.map((condition, index) => (
                                <tr className="table-row" key={condition.id} onClick={() => editCondition(index)}>
                                    {/* General Information */}
                                    <td style={{ border: '1px solid #ddd', padding: '4px',}}>{condition.name.toUpperCase()}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.description}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>
                                        {condition.symptoms.map((symptom) => symptom.label).join(', ')}
                                    </td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>
                                        {condition.commonMedications.map((med) => med.name).join(', ')}
                                    </td>

                                    {/* Cardio Constraints */}
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.cardioConstraints.rpeMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.cardioConstraints.rpeMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.cardioConstraints.weeklyFreqMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.cardioConstraints.weeklyFreqMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.cardioConstraints.durationMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.cardioConstraints.durationMax}</td>

                                    {/* Resistance Constraints */}
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.rpeMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.rpeMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.weeklyFreqMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.weeklyFreqMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.durationMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.durationMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.setsMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.setsMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.repsMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.repsMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.weeklyExercisesMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.weeklyExercisesMax}</td>

                                    {/* Flexibility Constraints */}
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.flexibilityConstraints.rpeMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.flexibilityConstraints.rpeMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.flexibilityConstraints.weeklyFreqMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.flexibilityConstraints.weeklyFreqMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.flexibilityConstraints.durationMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.flexibilityConstraints.durationMax}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    </div>

                    }
                </div>

            )}
        </div>
    );
};

export default DatabasePageLoggedInView;
