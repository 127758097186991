import { Container } from "react-bootstrap";
import { User } from "../models/user";
import GPDashboardPageLoggedInView from "../components/GPDashboardPageLoggedInView";
import DashboardLoggedOutView from "../components/DashboardPageLoggedOutView";
import HomePageLoggedOutView from "../components/HomePageLoggedOutView";

interface GPDashboardPageProps {
    loggedInUser: User | null,
    updateUser: (user:User | null) => void
}

const GPDashboardPage = ({ loggedInUser, updateUser }: GPDashboardPageProps) => {
    return (
        <Container>
            <>
                {loggedInUser
                    ? <GPDashboardPageLoggedInView 
                        user={loggedInUser}
                        onLogoutSuccessful={()=>updateUser(null)}
                        />
                    : <HomePageLoggedOutView 
                    onSignUpSuccessful={(user) => {
                        updateUser(user);
                    }}
                    onLoginSuccessful={(user) => {
                        updateUser(user);
                    }}
                    />
                }
            </>
        </Container>
    );
}

export default GPDashboardPage;